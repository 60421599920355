import moment from "moment";
import React from "react";
import MyDataTable from "../../../components/common/table/myDataTable";

const SellerDetails = React.forwardRef<any, { url: string }>(({ url }, ref) => {
  return (
    <MyDataTable
      url={url}
      dataKey="sellerCars"
      columns={[
        {
          id: "id",
          label: "id",
        },
        {
          label: "Brand",
          id: "car.brand.title",
        },
        {
          label: "Model",
          id: "car.model.title",
        },
        { id: "car.title", label: "Car" },
        {
          label: "parameters",
          render: (data) => (
            <ul>
              {data.car.parameters?.map((p) => (
                <li>
                  {p.name}: {p.value}
                </li>
              ))}
            </ul>
          ),
        },
        {
          label: "Date",
          align: "right",
          render: (data) => moment(data.created_at).format("Y-MM-DD HH:mm"),
        },
      ]}
    />
  );
});

export default SellerDetails;
