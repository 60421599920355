export const sellerFilter = [
    {
      key: "name",
      label: "სახელი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      key: "surname",
      label: "გვარი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      node: "input",
      key: "mobile",
      label: "ტელეფონის ნომერი",
      value: "",
      inputType: "search",
    },
    {
      node: "selectbox",
      key: "type",
      label: "ტიპი",
      value: [
        { id: 1, label: "იურიდიული" },
        { id: 2, label: "ფიზიკური" },
      ],
      inputType: "search",
    },
    {
      node: "selectbox",
      key: "verification_status",
      label: "ვერიფიკაციის სტატუსი",
      value: [
          { id: 1, label: "ვერიფიცირებული" },
          { id: 2, label: "დარეგისტრირებული" },
          { id: 3, label: "დაარეგისტრირა ინვენტორსმა" },
          { id: 4, label: "დაბლოკილი" },
      ],
      inputType: "search",
    },
    {
      node: "input",
      key: "shps",
      label: "შპს სახელწოდება",
      value: "",
      inputType: "search",
    },
    {
      node: "input",
      key: "indentify_code",
      label: "საიდენტიფიკაციო კოდი",
      value: "",
      inputType: "number",
    },
    {
        node: "input",
        key: "iban_address",
        label: "ანგარიშის ნომერი",
        value: "",
        inputType: "search",
    },
    {
        node: "input",
        key: "rating",
        label: "რეიტინგი",
        value: "",
        inputType: "number",
    },
  ];