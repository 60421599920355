import CloseRounded from "@mui/icons-material/CloseRounded";
import ManageSearch from "@mui/icons-material/ManageSearch";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, IconButton, Pagination } from "@mui/material";
import React, { useState } from "react";
import ActionButton from "../../../components/common/buttons/action-button";
import CircularLoader from "../../../components/common/circular-loader/circular-loader";
import DynamicFilter from "../../../components/common/dynamic-filter/dynamic-filter";
import OfferDetailModal from "../../../components/dashboard/offers/offer-detail-modal";
import RequestTable from "../../../components/dashboard/offers/request-table/request-table";
import { offersFillter } from "./helpers";
import useGetOfferList from "./hooks/useGetOffers";

const Offers = () => {
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState({});
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [offerPartId, setOfferPartId] = useState<boolean | null>(null);
  const [page, setPage] = useState(1);
  const { data, isFetching, refetch } = useGetOfferList(page, search);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleClose = (event, reason) => {
    if (!reason || reason == "backdropClick") {
      setShowDetailModal(false);
    }
  };

  const callFilterApi = (data) => {
    setSearch(data);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const showModal = (data) => {
    setOfferPartId(data);
    setShowDetailModal(true);
  };

  return (
    <Box pt={2} px={1}>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems="center"
        pb={2}
      >
        <Box mx={3}>
          <IconButton
            sx={{ backgroundColor: "neutral.900" }}
            title="სინქრონიზაცია"
            onClick={() => refetch()}
          >
            <SyncIcon
              className={isFetching ? "spin" : ""}
              sx={{ color: "secondary.main" }}
            />
          </IconButton>
        </Box>
        <Box>
          <ActionButton
            title="ფილტრები"
            icon={filter ? <CloseRounded /> : <ManageSearch />}
            action={() => setFilter((prev) => !prev)}
          />
        </Box>
      </Box>
      <Box>
        <DynamicFilter
          display={filter ? "block" : "none"}
          setSearch={setSearch}
          fields={offersFillter}
          saveData={callFilterApi}
          buttonTitle={"ძებნა"}
          refetch={refetch}
          loading={isFetching}
        />
      </Box>
      {isFetching ? (
        <CircularLoader />
      ) : (
        <RequestTable rows={data?.data ?? []} onActionClick={showModal} />
      )}
      <Box display={"flex"} justifyContent="center" p={2}>
        <Pagination
          count={data?.last_page}
          page={page}
          onChange={handleChange}
        />
      </Box>
      <OfferDetailModal
        visible={showDetailModal}
        handleClose={handleClose}
        offerPartId={offerPartId}
      ></OfferDetailModal>
    </Box>
  );
};

export default Offers;
