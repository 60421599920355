/** @format */

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/auth/index";

export default function useGetToken(token) {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo2] = useState({
    tokenExpaires: "",
    userToken: localStorage?.getItem("idToken") ?? "",
  });
  useEffect(() => {
    let userToken = JSON.parse(localStorage?.getItem("idToken"));
    let tokenExpaires = JSON.parse(localStorage?.getItem("expires"));
    let name = decodeURIComponent(localStorage?.getItem("name"));
    let adminRole = JSON.parse(localStorage?.getItem("adminRole"));
    let userSurname = JSON.parse(localStorage?.getItem("userSurname"));
    dispatch(
      setUserInfo({
        idToken: userToken,
        expires: tokenExpaires,
        name,
        userSurname,
        adminRole,
      })
    );
    if (userToken && tokenExpaires) {
      setUserInfo2({
        tokenExpaires,
        userToken,
      });
    } else {
      setUserInfo2({
        tokenExpaires: "",
        userToken: "",
      });
    }
  }, [token]);

  return {
    userInfo,
  };
}
