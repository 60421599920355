import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "react-query";
import CourierController from "../../../../controller/couriers";

export const UserVerifyStatus = ({ value, rowData }) => {
  const [veifyStatus, setVerifyStatus] = useState(value);
  const [dbStatus, setDbStatus] = useState({id:'', status:''})

  const changeVerificationStatus = (id, status)=>{
    return CourierController.changeVirificationStatus(id, status)
  }



  const changeUserVerificationStatus = (event) => {
    setVerifyStatus(event.target.value);
    setDbStatus((prev)=>({id:rowData.id, status:event.target.value}))
    setTimeout(() => {
      refetch()
    }, 1000);
  };

  const  {isFetching, refetch} = useQuery(['changeVerificationStatus', dbStatus.id], ()=>changeVerificationStatus(dbStatus.id, dbStatus.status), {
    enabled:false,
  })

  if (isFetching) {
    return <CircularProgress size={40} />;
  }

  return (
    <Select
      sx={{fontSize:'12px'}}
      labelId="demo-select-small"
      id="demo-select-small"
      value={veifyStatus}
      onChange={changeUserVerificationStatus}
    >
      {verificationStatus.map((verification) => (
        <MenuItem key={verification.type} sx={{fontSize:'12px'}} value={verification.type}>{verification.label}</MenuItem>
      ))}
    </Select>
  );
};

const verificationStatus = [
  { type: 1, label: "ვერიფიცირებული" },
  { type: 2, label: "დარეგისტრირებული" },
  { type: 3, label: "დაარეგისტრირა ინვენტორსმა" },
  { type: 4, label: "დაბლოკილი" },
];
