import { Box, IconButton, Typography } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PasswordIcon from '@mui/icons-material/Password';
import React, { useState } from "react";

const PasswordValue = ({password}:{password:string}) => {
  const [visible, toggleVisible] = useState(false);
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={'center'}>
      <Typography variant="body1" mt={1}>
        {visible ? password : "**********"}
      </Typography>
      <IconButton onClick={()=>toggleVisible((prev)=>!prev)}>
         {visible ? <VisibilityIcon/> : <VisibilityOffIcon/>}
      </IconButton>
    </Box>
  );
};

export default PasswordValue;
