import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import TextField from "@mui/material/TextField";
import { Box, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import CircularLoader from "../circular-loader/circular-loader";

interface Iform {
  fields: any[];
  userInput: any;
  loadingState: boolean;
  buttonTitle?: string;
  saveData: (data: any) => void;
}

const DynamicForm = forwardRef(
  ({ fields, userInput, loadingState, buttonTitle, saveData }: Iform, ref) => {
    const [hasInitialValues, setHasInitialValues] = useState<boolean>(false);
    const [loader, setLoader] = useState(false);
    const {
      register,
      handleSubmit,
      reset,
      control,
      formState: { errors },
    } = useForm({
      defaultValues: useMemo(() => {
        return {
          ...userInput,
        };
      }, [userInput]),
    });

    const [conditionalyValidate, setConditionalyValidate] = useState<{
      initial: string;
      key: string | number;
    }>({ initial: "", key: "" });

    /* set default values watch state changes and show spinner to set default value on initial render */

    useEffect(() => {
      setLoader(true);
      let timer = setTimeout(() => {
        setLoader(false);
      }, 1000);
      reset(userInput);
      return () => {
        clearTimeout(timer);
      };
    }, [userInput]);

    /* conditionaly show juridical person fileds */
    const removeFields = (fieldKey: string) => {
      return (
        (fieldKey == "indentify_code" ||
          fieldKey == "position" ||
          fieldKey == "shps") &&
        conditionalyValidate.initial == "type" &&
        conditionalyValidate.key == 2
      );
    };

    /* send clear filed method to parent component */
    useImperativeHandle(ref, () => ({
      resetFields() {
        reset();
      },
    }));

    if (loader) {
      return (
        <Box px={35}>
          <CircularLoader />
        </Box>
      );
    }

    return (
      <form onSubmit={handleSubmit((data) => saveData(data))}>
        <Box sx={{ flexGrow: 1, paddingY: "10px" }}>
          <Grid container spacing={2} rowSpacing={5}>
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                {removeFields(field.key) ? null : (
                  <Grid item xs={6}>
                    {field.node == "input" ? (
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "100%" }}
                          id={field.key}
                          type={field.inputType}
                          label={field.label}
                          variant="outlined"
                          {...register(`${field.key}`, field.inputValidation)}
                          error={errors[`${field.key}`] ? true : false}
                          helperText={
                            errors[`${field.key}`] && (
                              <>{errors[`${field.key}`].message}</>
                            )
                          }
                        />
                      </Box>
                    ) : (
                      <Box sx={{ positin: "relatvie" }}>
                        <Box sx={{ fontSize: 11, position: "absolute" }}>
                          {field.label}
                        </Box>
                        <Select
                          defaultValue={
                            userInput && userInput[`${field.key}`]
                              ? userInput[`${field.key}`]
                              : field.value[0].id
                          }
                          sx={{ fontSize: "12px", width: "100%" }}
                          labelId="demo-select-small"
                          id="demo-select-small"
                          label={field.label}
                          {...register(`${field.key}`)}
                          onChange={(e) =>
                            setConditionalyValidate({
                              initial: e.target.name,
                              key: e.target.value,
                            })
                          }
                          error={errors[`${field.key}`] ? true : false}
                        >
                          {field.value.map((select: any) => (
                            <MenuItem
                              key={select.id}
                              sx={{ fontSize: "12px" }}
                              value={select.id}
                            >
                              {select.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )}
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} my={4}>
          <LoadingButton
            loading={loadingState}
            type="submit"
            loadingPosition="end"
            endIcon={<SaveIcon />}
            variant="outlined"
          >
            <span style={{ marginTop: "8px" }}>{buttonTitle}</span>
          </LoadingButton>
        </Box>
      </form>
    );
  }
);

export default DynamicForm;
