import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { REQUEST_TABLE_COLUMNS } from "./request-table-columns";

export function RequestTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell key={"collapse"} align="center" />

        {REQUEST_TABLE_COLUMNS.map((column) => (
          <TableCell key={column.id} align="center" style={column.style ?? {}}>
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
