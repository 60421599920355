/**
 * /* eslint-disable
 *
 * @format
 */

import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneEnabled from "@mui/icons-material/PhoneEnabled";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { TextField, Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import useUserLogin from "../../../views/auth/hooks/useUserLogin";

interface Ipassword {
  password: string;
  mobile: string;
}
interface Imutation {
  mutate: (data: any) => void;
  isLoading: boolean;
}

const LoginForm = ({
  setCheckTokenStatus,
}: {
  setCheckTokenStatus: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { mutate: userLogin, isLoading }: Imutation =
    useUserLogin(setCheckTokenStatus);

  const login = useCallback((data: Ipassword) => {
    userLogin(data);
  }, []);

  return (
    <Box>
      <Box>
        <TextField
          id="outlined-basic"
          label="ტელეფონის ნომერი"
          variant="outlined"
          FormHelperTextProps={{ style: { fontSize: "11px" } }}
          size="small"
          type="number"
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PhoneEnabled />
              </InputAdornment>
            ),
          }}
          {...register("mobile", {
            required: { value: true, message: "ველის მითითება სავალდებულოა!" },
            pattern: {
              value: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: "შეიყვანეთ მხოლოდ რიცხვები!",
            },
            minLength: {
              value: 9,
              message: "მობილურის უნდა შეიცავდეს 9 სიმბოლოს!",
            },
            maxLength: {
              value: 9,
              message: "მობილურის უნდა შეიცავდეს 9 სიმბოლოს!",
            },
          })}
          error={errors.mobile ? true : false}
          helperText={errors.mobile && <>{errors.mobile.message}</>}
        />
      </Box>
      <Box mt={4}>
        <TextField
          id="outlined-basic2"
          label="პაროლი"
          variant="outlined"
          FormHelperTextProps={{ style: { fontSize: "11px" } }}
          size="small"
          type={showPassword ? "text" : "password"}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register("password", {
            required: { value: true, message: "ველის მითითება სავალდებულოა!" },
            minLength: {
              value: 8,
              message: "პაროლი უნდა შეიცავდეს 8 სიმბოლოს!",
            },
          })}
          error={errors.password ? true : false}
          helperText={errors.password && <>{errors.password.message}</>}
        />
      </Box>
      <Box mt={4}>
        <LoadingButton
          sx={{ width: "100%" }}
          onClick={handleSubmit((data: { password: string; mobile: string }) =>
            login(data)
          )}
          variant="contained"
          loading={isLoading}
          loadingIndicator="დაელოდეთ..."
        >
          სისტემაში შესვლა
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default LoginForm;
