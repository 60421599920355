export enum ChosenEntityCondition {
  originalNew = "originalNew",
  originalUsed = "originalUsed",
  replicaNew = "replicaNew",
}

export interface IColumn {
  id: string;
  label: string;
  minWidth?: number;
  style?: object;
  render?: (...args: any) => React.ReactNode;
}

export interface IOfferRow {
  id: number;
  offer_id: string | number;
  buyer_name: string;
  buyer_phone: string;
  seller_name: string;
  seller_phone: string;
  buyer_info: any;
  seller_info: any;
  car_manufacturer: string;
  car_model: string;
  part_name: string;
  part_number: string;
  catalog_info: any;
  grade: string;
  warranty: number | string;
  price: number;
  preparation_time: string;
  status: number | string;
  timer: number | string;
  created_at: string;
  all_offer_parts_count: number;
  request: {
    id: number;
    step: number;
  };
  part: {
    brand_id: string;
    model_id: string;
    car_id: string;
    group_id: string;
    group_title: string;
    id: string;
    description: string | null;
    name_en: string | null;
    name_ka: string | null;
    number: string | null;
    original_number: string | null;
    title: "საკეტი გადამზიდავი სამაგრით გამაგრილებლის რადიატორისთვის";
  };
  buyerCar: {
    car: {
      id: string;
      title: string;
      parameters: {
        key: string;
        name: string;
        value: string;
      }[];
      model: {
        title: string;
        image: string;
      };
      brand: {
        title: string;
        img: string;
      };
    };
  };
}
