import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const CircularLoader = () => {
  return (
    <Box display="flex" justifyContent="center" my={22}>
      <Box>
        <Box display="flex" justifyContent="center" mb={4}>
          <CircularProgress color="secondary" />
        </Box>
        <Box component="p">მიმდინარეობს მონაცემების დამუშავება...</Box>
      </Box>
    </Box>
  );
};

export default CircularLoader;
