import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDataApi } from "../../../config/api";
import _ from "lodash";

interface Column {
  id?: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  render?: (data?: any) => any;
}

export default function MyDataTable({
  columns = [],
  url: _url = "",
  dataKey = "data",
}: {
  columns?: Column[];
  url?: string;
  dataKey?: string;
}) {
  const [page, setPage] = useState(0);

  const [{ data, isLoading }, , setUrl] = useDataApi(_url);
  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    const isLoading = isLoadingRef.current;
    if (isLoading) return;

    setPage(newPage);
    setUrl(`${_url}?page=${newPage + 1}`);
  }, [setUrl]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              _.get(data, dataKey, []).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      if (column.render) {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.render(row)}
                          </TableCell>
                        );
                      }
                      const value = _.get(row, column.id);
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[30]}
        component="div"
        count={data?.total ?? 0}
        rowsPerPage={30}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}
