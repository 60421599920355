import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import ManageSearch from "@mui/icons-material/ManageSearch";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DynamicTable from "../../../components/common/table/table";
import ActionButton from "../../../components/common/buttons/action-button";
import SlideInModal from "../../../components/common/slide-in-modal/slide-in-modal";
import DynamicFilter from "../../../components/common/dynamic-filter/dynamic-filter";
import { sellerRateFilter } from "./helpers/filter";
import SellerRatingDetails from "../../../components/dashboard/seller-rating/seller-rating-details/seller-rating-details";

const SellerRating = () => {
  const [rateModal, setOpenRateModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const callFilterApi = (data) => {
    console.log(data);
  };

  function prepareTableData() {
    let arr = mainData.map((data) => {
      return { ...data };
    });
    setRows(arr);
  }

  useEffect(() => {
    prepareTableData();
  }, []);


  const seeSellerInfo = (sellerId)=>{
        setOpenRateModal(true)
        console.log(sellerId);
  }

  return (
    <Box sx={{ py: 3, px: 2 }}>
      <Box display={"flex"} justifyContent={"flex-end"} pb={2}>
        <Box>
          <ActionButton
            title="ფილტრები"
            icon={filter ? <CloseRounded /> : <ManageSearch />}
            action={() => setFilter((prev) => !prev)}
          />
        </Box>
      </Box>
      <Box>
        {filter ? (
          <DynamicFilter
            fields={sellerRateFilter}
            saveData={callFilterApi}
            buttonTitle={"ძებნა"}
          />
        ) : null}
      </Box>
      <DynamicTable tableColumns={tableColumns} tableRows={rows} action={seeSellerInfo} />
      <SlideInModal
        status={rateModal}
        title="გამყიდველის რეიტინგი"
        maxWidth="md"
        setClose={() => setOpenRateModal(false)}
      >
       <SellerRatingDetails/>
      </SlideInModal>
      <Box>
        <Paper sx={{ p: 2, mb: 4, mt: 2 }} elevation={2}>
          <Stack
            spacing={2}
            direction="row"
            display="flex"
            justifyContent="center"
          >
            <Pagination count={10} page={page} onChange={handleChange} />
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};

export default SellerRating;

const tableColumns = [
  { id: "name", label: "სახელი" },
  { id: "surname", label: "გვარი" },
  { id: "mobile", label: "ტელეფონის ნომერი" },
  { id: "rating", label: "რეიტინგი" },
  { id: "details", label: "დეტალურად" },
];

const mainData = [
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    sellerId:99828334
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    sellerId:99828332
  },
];
