import React, { useState } from 'react'
import Switch from '@mui/material/Switch';
const NotificationStatus = ({status}) => {
  const [notificationStatus, setNotificationStatus] = useState(status)

  const changeNotificationStatus = (event)=>{
    setNotificationStatus((prev)=> !prev)
  }
  return (
    <Switch value={notificationStatus} onChange={changeNotificationStatus} />
  )
}

export default NotificationStatus