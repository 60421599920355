/** @format */

import InventorsAPI from "../../config/api";
import { orderType } from "../../views/customers/orders/interfaces";




export default class OrderController {
  public static async getOrderList(page?:number, search?:any) {
    const params ={
      ...search
   }
    const response = await InventorsAPI.get(`/admin/orders?page=${page}`, {params, timeout:20000});
    return response.data;
  }
  
  public static async changeOrderStatus(type:orderType, id:number|string, status:number) {
    const response = await InventorsAPI.get(`/admin/order/${type}/${id}/${status}`);
    return response.data;
  }
  

  public static async changeUserData(type:string, id:string|number, uid:string|number,  data:any) {
    const response = await InventorsAPI.post(`/admin/users/update/${type}/${id}/${uid}`, data);
    return response.data;
  }

  public static async assignCourier(trId:string, cid:string) {
    const response = await InventorsAPI.get(`admin/order/courier/seller/${trId}/${cid}`);
    return response.data;
  }

}
