import React, { useState } from "react";
import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import Pagination from "@mui/material/Pagination";
import { Box, Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import MainPageController from "../../../../controller/main-page";
import CarInfoBlock from "./car-info-block/car-info-block";

interface ICarInfo {
  data:{
      brand:{
        title:string
        img:string|null
      }
      title:string
      id:string|number
      parameters:{
        name:string
        value:string
      }[]
  }[] | null,
  total:number|string,
  count:number
}


export const CarInfo = () => {
  const [carInfo, setCarInfo] = useState<ICarInfo>({ data:null , count: 0, total:""});
  const [page, setPage] = useState(1);
  const { addToast } = useToasts();

  const getCarInfos = (page = 1) => {
    return MainPageController.getCarInfo(page);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const { isFetching } = useQuery(
    ["getCarInfos", page],
    () => getCarInfos(page),
    {
      onSuccess: (data) => {
        setCarInfo({ data: data.data, count: data.last_page, total:data.total});
      },
      onError: (error) => {
        addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
    }
  );

  return (
    <Paper elevation={3}>
      <Box
        display={"flex"}
        p={2}
        flexDirection={"row"}
        justifyContent={"center"}
      >
        ჩამოტვირთული მანქანები (სულ {carInfo.total})
      </Box>
      {isFetching ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          py={23.4}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box>
          <Box p={3} maxHeight={352} overflow={"auto"}>
            {carInfo?.data?.map((info) => (
              <CarInfoBlock carTitle={`${info.brand.title} - ${info.title}`} parameters={info.parameters} logo={info.brand.img} key={info.id} />
            ))}
          </Box>
          <Box
            display={"flex"}
            p={2}
            flexDirection={"row"}
            justifyContent={"center"}
          >
            <Pagination
              count={carInfo.count}
              page={page}
              onChange={handleChange}
            />
          </Box>
        </Box>
      )}
    </Paper>
  );
};
