/**
 * /* eslint-disable
 *
 * @format
 */

import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { TextField, Box } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <Box sx={{ width: "400px" }}>
      <Box mt={4}>
        <TextField
          id="outlined-basic2"
          label="მიუთითეთ ახალი პაროლი"
          variant="outlined"
          FormHelperTextProps={{ style: { fontSize: "11px" } }}
          size="small"
          type={showPassword ? "text" : "password"}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register("password", {
            required: {
              value: true,
              message: "ველის მითითება სავალდებულოა!",
            },
            pattern: {
              value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
              message:
                "პაროლი უნდა შეიცავდეს დიდ-პატარა ასოებს და ციფრებს, მინიმალური სიგრძე 6 სიმბოლო!",
            },
          })}
          error={errors.password ? true : false}
          helperText={errors.password && <>{errors.password.message}</>}
        />
      </Box>
      <Box mt={4}>
        <LoadingButton
          sx={{ width: "100%" }}
          onClick={handleSubmit((data) => alert(data))}
          variant="contained"
          //    loading={isLoading}
          loadingIndicator="დაელოდეთ..."
        >
          პაროლის შეცვლა
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ResetPassword;
