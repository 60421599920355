import React from "react";
import Moment from "react-moment";

export const REQUEST_TABLE_COLUMNS = [
  {
    id: "user",
    label: "სახელი/გვარი",
    style: { minWidth: 200, maxWidth: 200, width: 200 },
    render(data) {
      return data?.firstname + " " + data?.lastname;
    },
  },
  {
    id: "user.mobile",
    label: "მყიდველის მობ",
    style: { minWidth: 200, maxWidth: 200, width: 200 },
  },
  {
    id: "part.name_en",
    label: "ნაწილის სახელი",
    style: { minWidth: 250, maxWidth: 250, width: 250 },
  },
  {
    id: "part.number",
    label: "ნაწილის ნომერი",
    style: { minWidth: 200, maxWidth: 200, width: 200 },
  },
  {
    id: "status",
    label: "მდგომარეობა",
  },
  {
    id: "step",
    label: "სტატუსი",
    render: (value, row) => {
      switch (row.request.step) {
        case 0:
          return "მიმდინარე";
        case 1:
          return "ელოდება მყიდველის პასუხს";
        case 3:
          return "დასრულებულია";
        default:
          return "";
      }
    },
  },
  {
    id: "request.created_at",
    label: "შემოსვლის თარიღი",
    render(value) {
      return <Moment locale="ka" format="LLL" date={value} />;
    },
  },
];
