/** @format */
import React from "react";
import PropTypes from "prop-types";

import { Box, Button, Link, ListItem, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

interface InavItem {
  href: string;
  title: string;
  icon: React.ReactNode;
  isExternal: boolean;
}

export const NavItem = ({ href, title, icon, isExternal }: InavItem) => {
  const location = useLocation();
  const active = location?.pathname == href ? true : false;
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      {isExternal ? (
        <Link href={href} target={"_blank"} style={{ marginTop: "8px" }}>
          <Button
            disableRipple
            startIcon={icon}
            sx={{
              backgroundColor: active && "rgba(255,255,255, 0.08)",
              borderRadius: 1,
              color: active ? "secondary.main" : "neutral.300",
              fontWeight: active && "fontWeightBold",
              justifyContent: "flex-start",
              px: 3,
              textAlign: "left",
              textTransform: "none",
              width: "100%",
              "& .MuiButton-startIcon": {
                color: active ? "secondary.main" : "neutral.400",
              },
              "&:hover": {
                backgroundColor: "rgba(255,255,255, 0.08)",
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1" fontSize={"12px"}>
                {title}
              </Typography>
            </Box>
          </Button>
        </Link>
      ) : (
        <NavLink
          to={href}
          style={{ textDecoration: "none", width: "100%", marginTop: "8px" }}
        >
          <Button
            disableRipple
            startIcon={icon}
            sx={{
              backgroundColor: active && "rgba(255,255,255, 0.08)",
              borderRadius: 1,
              color: active ? "secondary.main" : "neutral.300",
              fontWeight: active && "fontWeightBold",
              justifyContent: "flex-start",
              px: 3,
              textAlign: "left",
              textTransform: "none",
              width: "100%",
              "& .MuiButton-startIcon": {
                color: active ? "secondary.main" : "neutral.400",
              },
              "&:hover": {
                backgroundColor: "rgba(255,255,255, 0.08)",
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1" fontSize={"12px"}>
                {title}
              </Typography>
            </Box>
          </Button>
        </NavLink>
      )}
    </ListItem>
  );
};
