/** @format */

import { useNavigate } from "react-router-dom";

export default function useLogout(setCheckTokenStatus:React.Dispatch<React.SetStateAction<string>>) {
  const navigate = useNavigate();
  const logOut = () => {
    setCheckTokenStatus('');
    localStorage.removeItem("idToken");
    localStorage.removeItem("expires");
    window.location.reload()
  };

  return { logOut };
}
