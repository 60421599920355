/** @format */

import InventorsAPI from "../../config/api/index";

export default class MainPageController {
  public static async getUserCount() {
    const response = await InventorsAPI.get(`/user/count`);
    return response.data;
  }

  public static async getCarInfo(page:number) {
    const response = await InventorsAPI.get(`/catalog/cars/list?page=${page}`);
    return response.data;
  }
}
