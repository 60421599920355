import { Chip, Divider, Box} from "@mui/material";
import React, { useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import InventorsAPI, { baseURL } from "../../../../config/api";


interface IPartInfo {
    buyer_images: string[] | null,
    seller_images:string[] | null,
    buyer_comment:string | null,
    seller_comment:string | null,
    group_img:string|null
}


export default function PartInfoDetails({ part }:{part:IPartInfo}) {
  return (
     <Box>
        {part?.group_img ?         
        <Box mt={5}>
            <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
                <Chip label={'კატალოგი'} variant="outlined" />
            </Divider>
            <Box display="flex" alignItems="center" sx={{overflowX:'auto', mt:5}}>
                    <Zoom>
                            <img
                                alt="unsupported format"
                                src={`https:${part.group_img}`}
                                width="300"
                                style={{marginLeft:10, borderRadius:15}}
                            />
                    </Zoom>
            </Box>
        </Box> : null}
        {part?.seller_images && part?.buyer_images?.length ?         
        <Box mt={5}>
            <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
                <Chip label={'მყიდველის მიერ ატვირთული სურათები'} variant="outlined" />
            </Divider>
            <Box display="flex" alignItems="center" sx={{overflowX:'auto', mt:5}}>
                {part.buyer_images.map((img)=>(
                    <Zoom>
                            <img
                                alt="unsupported format"
                                src={`${baseURL}storage/${img}`}
                                width="300"
                                style={{marginLeft:10, borderRadius:15}}
                            />
                    </Zoom>
                ))}
            </Box>
        </Box> : null}
        {part?.seller_images && part?.seller_images?.length ?         
        <Box mt={5}>
            <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
                <Chip label={'გამყიდველის მიერ ატვირთული სურათები'} variant="outlined" />
            </Divider>
            <Box display="flex" alignItems="center" sx={{overflowX:'auto', mt:5}}>
                {part.seller_images.map((img)=>(
                    <Zoom>
                            <img
                                alt="unsupported format"
                                src={`${baseURL}storage/${img}`}
                                width="300"
                                style={{marginLeft:10, borderRadius:15}}
                            />
                    </Zoom>
                ))}
            </Box>
        </Box> : null}

     </Box>
  );
}
