/** @format */

import InventorsAPI from "../../config/api/index";

export default class CourierController {
  public static async addCourier(data:any) {
    const response = await InventorsAPI.post(`/admin/users/4`, data);
    return response.data;
  }

  public static async getCourier(page:number, search:any) {
    const params ={
       ...search
    }
    const response = await InventorsAPI.get(`/admin/users/4?page=${page}`, {params});
    return response.data;
  }

  public static async deleteCourier(id:number|string) {
    const response = await InventorsAPI.delete(`/admin/users/${id}`);
    return response.data;
  }

  public static async editCourier(data:any) {
    const response = await InventorsAPI.patch(`/admin/users/${data.id}`, data);
    return response.data;
  }

  public static async changeVirificationStatus(id:number, status:number) {
    const response = await InventorsAPI.get(`/admin/users/${id}/verificationstatus/${status}`);
    return response.data;
  }


}
