
import { Button } from "@mui/material"
import React from "react"


export default function ViaroButton({bgColor,children, action}:{bgColor?:string, children:React.ReactNode, action?:()=>void}){
    return(
        <Button 
            onClick={action}
            variant="contained" 
            style={{maxWidth: '35px', maxHeight: '35px', minWidth: '35px', minHeight: '35px', background:bgColor ? bgColor : '#7B8794'}}
        >
            {children}
       </Button>
    )
}