import { CloseRounded } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, IconButton, Tab } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDataApi } from "../../../config/api";
import CircularLoader from "../../common/circular-loader/circular-loader";
import { EmptyResult } from "../../common/empty-result/empty-result";
import SlideInModal from "../../common/slide-in-modal/slide-in-modal";
import BuyerInfo from "./buyer-info/buyer-info";
import PartInfo from "./part-info/part-info";
import SellerInfo from "./seller-info/seller-info";

export default function OfferDetailModal({
  offerPartId,
  handleClose,
  visible,
}: any) {
  const [currentTab, setCurrentTab] = useState<string>("buyer");
  const [{ data, isLoading, isError }, setData, setUrl] = useDataApi(null);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (offerPartId) {
      setUrl("admin/offerParts/" + offerPartId);
    }
  }, [offerPartId]);

  return (
    <SlideInModal
      status={visible}
      title={null}
      maxWidth={"lg"}
      showIcon={false}
    >
      <Box>
        <TabContext value={currentTab}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="მყიდველი" value="buyer" />
              <Tab label="გამყიდველი" value="seller" />
              <Tab label="ნაწილი" value="part" />
            </TabList>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          {isLoading || !data ? (
            <CircularLoader />
          ) : (
            <>
              <TabPanel value="buyer">
                <BuyerInfo
                  buyerInfo={{
                    ...data.buyer,
                    buyer_comment: data.buyer_comment,
                  }}
                />
              </TabPanel>
              <TabPanel value="seller">
                <SellerInfo
                  sellerInfo={{
                    ...data?.seller,
                    seller_comment: data.seller_comment,
                  }}
                />
              </TabPanel>
              <TabPanel value="part">
                <PartInfo
                  partInfo={{
                    ...data?.part,
                    buyerCar: data.buyerCar,
                    price: data.price,
                    warranty: data.warranty,
                  }}
                />
              </TabPanel>
            </>
          )}
        </TabContext>
      </Box>
    </SlideInModal>
  );
}
