import { Box, Button, Modal } from "@mui/material";
import React, { useState } from "react";
import MyDataTable from "../../../components/common/table/myDataTable";
import PromoDefault from "./promoDetail";

export default function Promo() {
  const [open, setOpen] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState<number | null>(null);
  const handleOpen = (id) => {
    setSelectedPromo(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ py: 3, px: 2 }}>
        <MyDataTable
          url="/admin/promocodes"
          columns={[
            {
              label: "Name",
              render: (data) =>
                data.user
                  ? `${data.user.firstname} ${data.user.lastname}`
                  : data.seller_name,
            },
            {
              label: "Mobile",
              render: (data) =>
                data.user ? `${data.user.mobile}` : data.mobile,
            },
            { id: "cashback", label: "Cashback %", align: "right" },
            { id: "promo_code", label: "Promo\u00a0Code", minWidth: 100 },
            {
              id: "transactions_sum_cashback",
              label: "Total Cashback ( GEL )",
              align: "right",
              format: (value: number) => value.toLocaleString("en-US"),
            },
            {
              label: "Action",
              minWidth: 170,
              align: "right",
              render: (data) => (
                <Button variant="contained" onClick={() => handleOpen(data.id)}>
                  Detail
                </Button>
              ),
            },
          ]}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PromoDefault url={"/admin/promocodes/" + selectedPromo} />
      </Modal>
    </>
  );
}
