import { Box } from "@mui/material";
import moment from "moment";
import React from "react";
import MyDataTable from "../../../components/common/table/myDataTable";

const PromoDetail = React.forwardRef<any, { url: string }>(({ url }, ref) => {
  return (
    <Box sx={{ py: 3, px: 2 }}>
      <MyDataTable
        url={url}
        dataKey="transactions"
        columns={[
          {
            id: "id",
            label: "Transaction ID",
          },
          {
            label: "User",
            render: (data) =>
              data.user ? `${data.user.firstname} ${data.user.lastname}` : null,
          },
          {
            label: "Mobile",
            render: (data) => (data.user ? `${data.user.mobile}` : data.mobile),
          },
          { id: "price", label: "Transaction amount ( GEL )", align: "right" },
          { id: "cashback", label: "Cashback ( GEL )", align: "right" },
          {
            label: "Date",
            render: (data) => moment(data.created_at).format("Y-MM-DD HH:mm"),
          },
        ]}
      />
    </Box>
  );
});

export default PromoDetail;
