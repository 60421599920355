/** @format */

import InventorsAPI from "../../config/api/index";

export default class StaffController {
  public static async addStaff(data: any) {
    const response = await InventorsAPI.post(
      `/admin/users/${data?.role}`,
      data
    );
    return response.data;
  }

  public static async getStaff(page: number, search: any) {
    const params = {
      ...search,
    };
    const response = await InventorsAPI.get(
      `/admin/users/${params?.role ? params.role : 8}?page=${page}`,
      { params }
    );
    return response.data;
  }

  public static async deleteStaff(id: string | number) {
    const response = await InventorsAPI.delete(`/admin/users/${id}`);
    return response.data;
  }

  public static async editStaff(data: any) {
    const response = await InventorsAPI.patch(`/admin/users/${data.id}`, data);
    return response.data;
  }
}
