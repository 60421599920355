/** @format */
import React, { createContext } from "react";
import PropTypes from "prop-types";

const AuthContext = createContext();
export function AuthProvider({ children, value }) {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;

AuthProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};
