import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import "moment/locale/ka";
import React from "react";
import { IOfferRow } from "../../../../views/customers/offers/interface";
import { REQUEST_TABLE_COLUMNS } from "./request-table-columns";
import { RequestTableHead } from "./request-table-head";
import { OfferTableRow } from "./request-rows/request-rows";

type Props = {
  rows: IOfferRow[];
  onActionClick?: (data: number) => void;
};

export default function RequestTable({ rows, onActionClick }: Props) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", mb: 0, minHeight: 600 }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <RequestTableHead />

          <TableBody>
            {rows.map((row) => {
              return (
                <OfferTableRow
                  row={row}
                  columns={REQUEST_TABLE_COLUMNS}
                  onActionClick={onActionClick}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}