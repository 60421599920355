import Home from "@mui/icons-material/Home";
import Group from "@mui/icons-material/Group";
import Grade from "@mui/icons-material/Grade";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PaidIcon from "@mui/icons-material/Paid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const RouteLink = [
  {
    href: "/dashboard",
    title: "მთავარი",
    icon: <Home />,
    isExternal: false,
  },
  // {
  //     href: "/customers/sellers",
  //     title: "გამყიდველი",
  //     icon: <StorefrontIcon />
  // },
  // {
  //     href: "/customers/rate",
  //     title: "გამყიდველის რეიტინგი",
  //     icon: <Grade />
  // },
  // {
  //     href: "/customers/buyer",
  //     title: "მყიდველი",
  //     icon: <PersonIcon />
  // },
  {
    href: "/customers/staff",
    title: "შიდა სტაფი",
    icon: <SupportAgentIcon />,
    isExternal: false,
  },
  {
    href: "/customers/couriers",
    title: "კურიერები",
    icon: <DeliveryDiningIcon />,
    isExternal: false,
  },
  {
    href: "/customers/offers",
    title: "შეთავაზებები",
    icon: <LocalOfferIcon />,
    isExternal: false,
  },
  {
    href: "/customers/orders",
    title: "შეკვეთები",
    icon: <PaidIcon />,
    isExternal: false,
  },
  {
    href: "/customers/promo",
    title: "Promo codes",
    icon: <PaidIcon />,
    isExternal: false,
  },
  {
    href: "/customers/buyer",
    title: "Buyers",
    icon: <PaidIcon />,
    isExternal: false,
  },
  {
    href: "/customers/sellers",
    title: "Seller",
    icon: <PaidIcon />,
    isExternal: false,
  },
  {
    href: "https://viarohelp.zendesk.com/",
    title: "მომხმარებელთა მხარდაჭერა",
    icon: <WhatsAppIcon />,
    isExternal: true,
  },
];
