/** @format */

import axios from "axios";
import { useEffect, useState } from "react";
export const baseURL =  "https://app.viaro.ge/";
const InventorsAPI = axios.create({
  baseURL: baseURL+"api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 5000,
});




InventorsAPI.interceptors.request.use(
  function(config) {
    const token = JSON.parse(localStorage?.getItem("idToken")); 
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);


export const useDataApi = (
  initialUrl,
  initialData = null,
  defaultData = null
) : any => {
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await InventorsAPI.get(url);
        if (!didCancel) {
          setData(result?.data);
        }
      } catch (error) {
        console.error(url, error);
        if (!didCancel) {
          setIsError(true);
          setData(defaultData());
        }
      }

      if (!didCancel) {
        setIsLoading(false);
      }
    };

    if (url && url.length) {
      fetchData();
    } else {
      setIsLoading(false);
    }

    return () => {
      didCancel = true;
    };
  }, [url]);

  return [{ data, isLoading, isError }, setData, setUrl];
};



export default InventorsAPI;
