import { ChosenEntityCondition } from "../interface";



export function ChosenEntityTitle(value:ChosenEntityCondition){
    const title = {
       [ChosenEntityCondition.originalNew]: 'ახალი/ორიგინალი',
       [ChosenEntityCondition.originalUsed]: 'მეორადი/ორიგინალი',
       [ChosenEntityCondition.replicaNew]: 'ახალი/არაორიგინალი'
    }

    return  title[value]
}



export const offersFillter = [
    {
      key: "seller_fname",
      label: "გამყიდველის სახელი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      key: "seller_lname",
      label: "გამყიდველის გვარი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      node: "input",
      key: "seller_mobile",
      label: "გამყიდველის ტელეფონის ნომერი",
      value: "",
      inputType: "number",
    },
    // {
    //   node: "input",
    //   key: "seller_pid",
    //   label: "გამყიდველის პირადი ნომერი",
    //   value: "",
    //   inputType: "number",
    // },
    {
        key: "buyer_fname",
        label: "მყიდველის სახელი",
        value: "",
        node: "input",
        inputType: "search",
      },
      {
        key: "buyer_lname",
        label: "მყიდველის გვარი",
        value: "",
        node: "input",
        inputType: "search",
      },
      {
        node: "input",
        key: "buyer_mobile",
        label: "მყიდველის ტელეფონის ნომერი",
        value: "",
        inputType: "number",
      },
      // {
      //   node: "input",
      //   key: "buyer_pid",
      //   label: "მყიდველის პირადი ნომერი",
      //   value: "",
      //   inputType: "number",
      // },
      {
        node: "input",
        key: "part_name",
        label: "ნაწილის სახელი",
        value: "",
        inputType: "search",
      },
      {
        node: "input",
        key: "part_number",
        label: "ნაწილის ნომერი",
        value: "",
        inputType: "search",
      },
      {
        node: "selectbox",
        key: "status",
        label: "სტატუსები",
        value: [
          {id:null,  label:'არჩევა', time:0},
          {id:1,  label:'მიმდინარე', time:0},
          {id:2,  label:'ელოდება მომხმარებლის პასუხს', time:0},
          {id:3,  label:'პასუხის გაცემის დრო ამოიწურა(24სთ)', time:0},
          {id:4,  label:'შეთავაზების დრო ამოიწურა(40წთ)', time:0},
          {id:5,  label:'არ მაქვს მარაგში', time:0},
          {id:6,  label:'მუშავდება(10 წთ)', time:0},
          {id:8,  label:'კორექტირება მოთხოვნილი', time:0},
          {id:9,  label:'უარყოფილი შეკვეთა', time:0},
          {id:10, label:'დადასტურებული შეკვეთა', time:0},
          {id:11, label:'შეკვეთა მზადდება', time:0},
          {id:13, label:'კურიერზე გადაცემული', time:0},
          {id:14, label:'წარმატებული', time:0},
          {id:23, label:'დაბრუნებული', time:0},
          {id:18, label:'კურიერის მოლოდინში', time:0},
          {id:20, label:'დაწუნებული (კურიერის მიერ)', time:0},
          {id:21, label:'დაწუნებული (კლიენტის მიერ)', time:0},
          {id:22, label:'ვერ ჩაბარებული', time:0}
        ],
        inputType: "search",
      },
  ];