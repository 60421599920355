import React, { useCallback, useState } from "react";
import { Box, Paper } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import ManageSearch from "@mui/icons-material/ManageSearch";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DynamicTable from "../../../components/common/table/table";
import ActionButton from "../../../components/common/buttons/action-button";
import SlideInModal from "../../../components/common/slide-in-modal/slide-in-modal";
import { courierFilter } from "./helpers/filter";
import DynamicFilter from "../../../components/common/dynamic-filter/dynamic-filter";
import { AddCourier } from "../../../components/dashboard/couriers/add-couriers/add-couriers";
import { tableColumns } from "./helpers/dynamicValues";
import useDeleteCourier from "./hooks/useDeleteCourier";
import useGetCouriers from "./hooks/useGetCouriers";
import CircularLoader from "../../../components/common/circular-loader/circular-loader";
import useDownloadExcell from "../../../global/hooks/useDownloadExcell";


const Couriers = () => {
  const [openAddCourierModal, setOpenAddCourierModal] = useState(false);
  const [edit, setEdit] = useState({ modalStatus: false, info: {} });
  console.log(edit);
  
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState({});
  const [page, setPage] = useState(1);

  //CRUD OPERATIONS
  const { data, isFetching, refetch } = useGetCouriers(page, search);
  const { mutate } = useDeleteCourier(refetch);
  const {downloadExcell, loading}= useDownloadExcell()

  const deleteCourier = useCallback((userId) => {
    if (confirm('ნამდვილად გსურთ მითითებული იუზერის წაშლა?')) {
      mutate(userId)
    }
  }, []);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  
  //FILTER
  const callFilterApi = (data) => {
    setSearch(data);
    setTimeout(() => {
      refetch()
    }, 500);
  };

  return (
    <Box sx={{ py: 3, px: 2 }}>
      <Box display={"flex"} justifyContent={"flex-end"} pb={2}>
        <Box mx={3}>
          <ActionButton
            title="ექსელის ექსპორტი"
            icon={loading ? <HourglassBottomIcon/> : <FileDownloadIcon/>}
            bgColor={'secondary.main'}
            action={()=>downloadExcell('/admin/users/4/export', 'კურიერები')}
          />
        </Box>
        <Box mx={3}>
          <ActionButton
            title="ფილტრები"
            icon={filter ? <CloseRounded /> : <ManageSearch />}
            action={() => setFilter((prev) => !prev)}
          />
        </Box>
        <ActionButton
          title="კურიერის რეგისტრაცია"
          icon={<AddCircle />}
          action={() => setOpenAddCourierModal(true)}
        />
      </Box>
      <Box>

          <DynamicFilter
            display={filter ? 'block' : 'none'}
            fields={courierFilter}
            saveData={callFilterApi}
            buttonTitle={"ძებნა"}
            setSearch={setSearch}
            refetch={refetch}
            loading={isFetching}
          />
      </Box>
      {isFetching ? (
        <CircularLoader />
      ) : (
        <DynamicTable
          tableColumns={tableColumns}
          tableRows={data?.couriersData ?? []}
          editRow={setEdit}
          deleteAction={deleteCourier}
        />
      )}

      <SlideInModal
        status={openAddCourierModal}
        title="კურიერის დამატება"
        maxWidth="lg"
        setClose={() => setOpenAddCourierModal(false)}
      >
        <AddCourier
          refetchData={refetch}
          hideModal={() => setOpenAddCourierModal(false)}
        />
      </SlideInModal>

      {/* edit modal */}
      <SlideInModal
        status={edit.modalStatus}
        title="მონაცემების რედაქტირება"
        maxWidth="lg"
        setClose={() => setEdit({ modalStatus: false, info: {} })}
      >
        <AddCourier
          initialStateProp={edit?.info}
          refetchData={refetch}
          editAction={true}
          hideModal={() => setEdit({ modalStatus: false, info: {} })}
        />
      </SlideInModal>
      <Box>
        <Paper sx={{ p: 2, mb: 4, mt: 2 }} elevation={2}>
          <Stack
            spacing={2}
            direction="row"
            display="flex"
            justifyContent="center"
          >
            <Pagination
              count={data?.last_page}
              page={page}
              onChange={handlePaginationChange}
            />
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};

export default Couriers;
