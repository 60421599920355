import React, { useCallback, useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import ManageSearch from "@mui/icons-material/ManageSearch";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DynamicTable from "../../../components/common/table/table";
import ActionButton from "../../../components/common/buttons/action-button";
import SlideInModal from "../../../components/common/slide-in-modal/slide-in-modal";
import DynamicFilter from "../../../components/common/dynamic-filter/dynamic-filter";
import { staffFilter } from "./helpers/filter";
import { AddStaffMembers } from "../../../components/dashboard/staff/add-staff-members/add-staff-members";
import useDownloadExcell from "../../../global/hooks/useDownloadExcell";
import CircularLoader from "../../../components/common/circular-loader/circular-loader";
import { tableColumns } from "./helpers/dynamicValues";
import useGetStaffMembers from "./hooks/useGetStaffMembers";
import useDeleteStaffMember from "./hooks/useDeleteStaffMember";

const Staff = () => {
  const [openAddByerModal, setOpenAddBuyerModal] = useState(false);
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState({});
  const [page, setPage] = useState(1);
  const [edit, setEdit] = useState({ modalStatus: false, info: {} });
  const { downloadExcell, loading } = useDownloadExcell();
  const { data, isFetching, refetch } = useGetStaffMembers(page, search);
  const { mutate } = useDeleteStaffMember(refetch);

  const deleteStaffMember = useCallback((userId) => {
    if (confirm("ნამდვილად გსურთ მითითებული იუზერის წაშლა?")) {
      mutate(userId);
    }
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };


  
  //FILTER
  const callFilterApi = (data) => {
    setSearch(data);
    setTimeout(() => {
      refetch()
    }, 500);
  };

  return (
    <Box sx={{ py: 3, px: 2 }}>
      <Box display={"flex"} justifyContent={"flex-end"} pb={2}>
        <Box mx={3}>
          <ActionButton
            title="ექსელის ექსპორტი"
            icon={loading ? <HourglassBottomIcon /> : <FileDownloadIcon />}
            bgColor={"secondary.main"}
            action={() =>
              downloadExcell(`/admin/users/${8 | 16}/export`, "სტაფი")
            }
          />
        </Box>
        <Box mx={3}>
          <ActionButton
            title="ფილტრები"
            icon={filter ? <CloseRounded /> : <ManageSearch />}
            action={() => setFilter((prev) => !prev)}
          />
        </Box>
        <ActionButton
          title="პერსონალის რეგისტრაცია"
          icon={<AddCircle />}
          action={() => setOpenAddBuyerModal(true)}
        />
      </Box>
      <Box>
          <DynamicFilter
            display={filter ? 'block' : 'none'}
            setSearch={setSearch}
            fields={staffFilter}
            saveData={callFilterApi}
            buttonTitle={"ძებნა"}
            refetch={refetch}
            loading={isFetching}
          />
      </Box>
      {isFetching ? (
        <CircularLoader />
      ) : (
        <DynamicTable
          tableColumns={tableColumns}
          deleteAction={deleteStaffMember}
          tableRows={data?.staffData ?? []}
          editRow={setEdit}
        />
      )}
      <SlideInModal
        status={openAddByerModal}
        title="პერსონალის დამატება"
        maxWidth="lg"
        setClose={() => setOpenAddBuyerModal(false)}
      >
        <AddStaffMembers
          hideModal={() => setOpenAddBuyerModal(false)}
          refetchData={refetch}
        />
      </SlideInModal>
      {/* edit modal */}
      <SlideInModal
        status={edit.modalStatus}
        title="მონაცემების რედაქტირება"
        maxWidth="lg"
        setClose={() => setEdit({ modalStatus: false, info: {} })}
      >
        <AddStaffMembers
          initialStateProp={edit?.info}
          refetchData={refetch}
          editAction={true}
          hideModal={() => setEdit({ modalStatus: false, info: {} })}
        />
      </SlideInModal>
      <Box>
        <Paper sx={{ p: 2, mb: 4, mt: 2 }} elevation={2}>
          <Stack
            spacing={2}
            direction="row"
            display="flex"
            justifyContent="center"
          >
            <Pagination
              count={data?.last_page}
              page={page}
              onChange={handleChange}
            />
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};

export default Staff;
