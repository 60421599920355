export const courierFilter = [
    {
        key: "name",
        label: "სახელი",
        value: "",
        node: "input",
        inputType: "search",
    },
    {
        key: "surname",
        label: "გვარი",
        value: "",
        node: "input",
        inputType: "search",
    },
    {
        node: "input",
        key: "mobile",
        label: "ტელეფონის ნომერი",
        value: "",
        inputType: "search",
    },
    {
        node: "input",
        key: "car_type",
        label: "მანქანის ტიპი",
        value: "",
        inputType: "search",
    },
    {
        node: "input",
        key: "personal_id",
        label: "პირადი ნომერი",
        value: "",
        inputType: "number",
    },
    {
        node: "input",
        key: "city",
        label: "ქალაქი",
        value: "",
        inputType: "text",
    },
    {
        node: "selectbox",
        key: "verification_status",
        label: "ვერიფიკაციის სტატუსი",
        value: [
            { id: 1, label: "ვერიფიცირებული" },
            { id: 2, label: "დარეგისტრირებული" },
            { id: 3, label: "დაარეგისტრირა ინვენტორსმა" },
            { id: 4, label: "დაბლოკილი" },
        ],
        inputType: "search",
      },

];