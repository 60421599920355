import {
  Box,
  Collapse,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ViaroButton from "./viaro-button";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import ViaroModal from "./viaro-modal";
import OrderStatus from "./order-status";
import {
  statuses,
  statusesForTakeByMyself,
} from "../../../views/customers/orders/helpers";
import useChangeStatus from "../../../views/customers/orders/hooks/useChangeStatus";
import { orderType } from "../../../views/customers/orders/interfaces";
import { entityStatusTitle, warrantyStatusTitle } from "../../../global/helper";
import { useRenderDeliveryMethod } from "../../../views/customers/orders/helpers/use-render-delivery";
import CourierSearch from "./courier-search";

export default function RowLevel2(props) {
  const {
    row,
    setDetailsModal,
    setSellerModal,
    setImageModal,
    data,
    refetch,
    setPage,
    city,
    setSellerInfo,
    buyerAddress,
    isCourier,
    couriers,
  } = props;

  const { changeOrderStatus } = useChangeStatus();
  const [open, setOpen] = useState(false);
  const openSellerModal = () => {
    setSellerModal({
      status: true,
      currentUserId: row.seller_id,
      offerId: row.offer?.id,
    });
    setSellerInfo({
      name: row.seller.firstname,
      surname: row.seller.lastname,
      phone: row.seller.mobile,
      address: row.offer?.address,
      juridical_name: row.seller.legal?.title,
      identify_code: row.offer?.id_number,
      iban: row.offer?.account,
    });
  };
  const changeLevel2Status = (
    orderType: orderType,
    id: string,
    status: number
  ) => {
    changeOrderStatus(orderType, id, status).then(() => {
      refetch();
    });
  };
  
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          backgroundColor: "#F8F8F8",
          borderTop: "1px solid black",
        }}
      >
        <TableCell align="center" component="th" scope="row">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box component="p">
              {row.seller.firstname} {row.seller.lastname}
            </Box>
            <IconButton onClick={openSellerModal}>
              <ErrorOutlineIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="center">{row.seller.mobile}</TableCell>
        <TableCell align="center" style={{ width: 170 }}>
          <Link
            href={`https://maps.google.com/?q=${row?.offer?.latitude},${row?.offer?.longitude}`}
            color="viaro.green"
            target="_blank"
          >
            {row?.offer?.address}
          </Link>
        </TableCell>
        <TableCell align="center">
          {useRenderDeliveryMethod(row.delivery_method, city)}
        </TableCell>
        <TableCell align="center">
          {row.delivery_time ? `${row.delivery_time} სთ` : "--"}
        </TableCell>
        <TableCell align="center" sx={{ maxWidth: 130 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box component="p">
              <Box component={"span"} color="viaro.green">
                {row.rating.success}
              </Box>
              /{row.rating.total}
            </Box>
            <Tooltip
              title={`შეფასება ${row.rating.rate}`}
              arrow
              placement="top-end"
            >
              <IconButton>
                <ErrorOutlineIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell align="center" sx={{ maxWidth: 130 }}>
          {row.comment ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box component="p">
                <Typography
                  sx={{
                    fontSize: 11,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {row.comment}
                </Typography>
              </Box>
              <Tooltip title={row.comment} arrow placement="top-end">
                <IconButton>
                  <ErrorOutlineIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            "არ არის მითითებული"
          )}
        </TableCell>

        <TableCell align="center">{row.offer?.time} სთ</TableCell>
        <TableCell align="center">
          <Box component="p" color="viaro.green">
            {row.parts.length}
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box component="p" color="viaro.green">
            {row.offer?.commission} ლარი
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box component="p" color="viaro.green">
            {row.offer?.final_price} ლარი
          </Box>
        </TableCell>
        <TableCell>
          {isCourier == "Courier" ? (
            <CourierSearch
              couriers={couriers}
              refetch={refetch}
              currentCourier={row.courier}
              trxId={row.id}
            />
          ) : (
            "---"
          )}
        </TableCell>
        <TableCell sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <ViaroButton
              bgColor={row.status == 9 ? "red" : null}
              action={() => changeLevel2Status(orderType.seller, row.id, 9)}
            >
              <DeleteIcon />
            </ViaroButton>
          </Box>
          <Box ml={2}>
            <ViaroButton
              bgColor={row.status == 14 ? "#00DC84" : null}
              action={() => changeLevel2Status(orderType.seller, row.id, 14)}
            >
              <DoneAllIcon />
            </ViaroButton>
          </Box>
          <Box ml={2}>
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ color: "viaro.gray", border: "1px solid", borderRadius: 2 }}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} component="tr" timeout="auto" unmountOnExit>
            {row.parts.map((item) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "118%" }}
              >
                {/* about part */}
                <Box display="flex" alignItems="center">
                  <Box
                    onClick={() =>
                      setImageModal({
                        status: true,
                        uploadedImages: [...item.offerPart?.seller_images],
                        dbImages: [item.part.group_img],
                        partName: item.part.title,
                        coordinates: {
                          ...item.part?.coordinates,
                          groupId: item.part.group_id,
                        },
                      })
                    }
                    mt={1}
                  >
                    <img
                      src={`https://${item.part.group_img}`}
                      style={{
                        height: 100,
                        width: 100,
                        borderRadius: "4px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                  <Box ml={2} width={300}>
                    <Box component="p" fontWeight="bold">
                      {item.part.title}
                    </Box>
                    <Box display="flex" alignItems="center" mt={0.5}>
                      <Box component="p" color="viaro.green" fontWeight="bold">
                        {item.part?.number}
                      </Box>
                      <Box component="p" mx={1}>
                        •
                      </Box>
                      <Box component="p" fontWeight="lighter">
                        {item.part.title}
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" mt={0.5}>
                      <Box component="p">
                        {entityStatusTitle(item.offerPart.cond)}
                      </Box>
                      <Box component="p" fontWeight="lighter">
                        {item.offerPart.grade}
                      </Box>
                      <Box component="p" mx={1}>
                        •
                      </Box>
                      <Box component="p" fontWeight="lighter">
                        {warrantyStatusTitle(item.offerPart.warranty)}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box mx={5} maxWidth={140} width="100%">
                  <Box component="p" fontWeight="bold" pb={1}>
                    Grade აღწერა
                  </Box>
                  <Box component="p" fontSize="10px">
                    {item.offerPart.seller_comment}
                  </Box>
                </Box>
                <Box maxWidth={150} mx={1} width="100%">
                  <Box component="p" fontWeight="bold" pb={1} fontSize="11px">
                    მყიდველის კომენტარი
                  </Box>
                  <Box component="p" fontSize="10px">
                    {item.offerPart.buyer_comment ??
                      "კომენტარი არ არის დამატებული"}
                  </Box>
                </Box>
                <Box mx={3} maxWidth={90} width="100%" textAlign="center">
                  <Box component="p" fontWeight="bold" pb={1}>
                    ფასი
                  </Box>
                  <Box component="p" color="viaro.green" textAlign="center">
                    {item.offerPart.price} ლ
                  </Box>
                </Box>
                <Box mx={3} maxWidth={170}>
                  <OrderStatus
                    statuses={
                      row.delivery_time ? statuses : statusesForTakeByMyself
                    }
                    currentStatus={item.offerPart.status}
                    statusUpdatedAt={item.offerPart.status_updated_at}
                    refetch={refetch}
                    level={orderType.part}
                    id={item.offerPart.id}
                  />
                </Box>
                <Box display="flex" alignItems="center" ml={2}>
                  <Box>
                    <ViaroButton
                      bgColor={item.offerPart.status == 9 ? "red" : null}
                      action={() =>
                        changeLevel2Status(orderType.part, item.offerPart.id, 9)
                      }
                    >
                      <CloseIcon />
                    </ViaroButton>
                  </Box>
                  <Box ml={2}>
                    <ViaroButton
                      bgColor={item.offerPart.status == 14 ? "#00DC84" : null}
                      action={() =>
                        changeLevel2Status(
                          orderType.part,
                          item.offerPart.id,
                          14
                        )
                      }
                    >
                      <DoneAllIcon />
                    </ViaroButton>
                  </Box>
                  <IconButton
                    onClick={() =>
                      setDetailsModal({
                        status: true,
                        data: {
                          ...item,
                          additional_comment: row.offer?.description,
                        },
                      })
                    }
                    sx={{ border: "2px solid", ml: 3 }}
                  >
                    <MoreHorizIcon sx={{ fontSize: "13px" }} />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
