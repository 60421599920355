import { Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton, Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import React, { useState } from "react";
import { IOfferRow } from "../../../../../views/customers/offers/interface";
import ViaroModal from "../../../orders/viaro-modal";

type Props = {
  row: IOfferRow;
  handleReschedule: () => void;
};

export function RescheduleRow({ row, handleReschedule }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <TableCell align="center" colSpan={7}>
      <Button variant="contained" onClick={handleReschedule}>
        Reschedule
      </Button>
      <IconButton
        color="primary"
        component="label"
        onClick={() => setIsModalVisible(true)}
      >
        <Visibility />
      </IconButton>

      <ViaroModal
        maxWidth="md"
        title="დეტალური მონაცემები"
        status={isModalVisible}
        setClose={() => setIsModalVisible(false)}
      >
        <Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Brand</TableCell>
                <TableCell>{row.buyerCar?.car?.brand?.title || '--'}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Model</TableCell>
                <TableCell>{row.buyerCar?.car?.model?.title || '--'}</TableCell>
              </TableRow>

              {row.buyerCar?.car?.parameters?.map((parameter) => (
                <TableRow>
                  <TableCell>{parameter.name}</TableCell>
                  <TableCell>{parameter.value}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell>Part Number</TableCell>
                <TableCell>{row.part.number}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Original Part Number</TableCell>
                <TableCell>{row.part.original_number}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Part Name</TableCell>
                <TableCell>{row.part.name_en}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </ViaroModal>
    </TableCell>
  );
}
