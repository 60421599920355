import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { courierRegistration } from "../../../../views/customers/couriers/helpers/dynamicValues";
import CourierController from "../../../../controller/couriers";
import DynamicForm from "../../../common/dynamic-form/dynamic-form";


interface IinitalProps {
    mobile: string | number,
    name:string,
    surname:string,
    car_type: string,
    personal_id:string|number,
    iban: string |number,
    city:String,
    auto_type:number
}

interface IcourierProps {
  hideModal?:()=>void,
  refetchData?:()=>void,
  editAction?:boolean,
  initialStateProp?:IinitalProps | any
}


let initialState:IinitalProps = {
  mobile: "",
  name: "",
  surname: "",
  car_type: "",
  auto_type:0,
  personal_id: "",
  iban: "",
  city: "",
};

export const AddCourier = ({ initialStateProp, hideModal, refetchData, editAction}:IcourierProps) => {
  const { addToast } = useToasts();
  const childRefs = useRef<{resetFields:()=>void}>()

  const [userInput, setUserInput] = useState(
    initialStateProp ? initialStateProp : initialState
  );

  const AddCourierInfo = (data:IinitalProps) => {
    return CourierController.addCourier(data);
  };

  const EditCourier = (data:IinitalProps)=>{
    return CourierController.editCourier(data)
  }

  const {mutate, isLoading} = useMutation(editAction ? EditCourier : AddCourierInfo, {
    onSuccess: (data) => {
      addToast(`ოპერაცია წარმატებით შესრულდა!`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      hideModal()
      childRefs.current?.resetFields()
      refetchData()
    },
    onError: (error) => {
      addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    },
  });

  const mockApiCall = (data) => {
    const requestBody = {...data, role:4}
    mutate(requestBody)
  };

  useEffect(() => {
    setUserInput(initialStateProp);
  }, [initialStateProp]);


  return (
    <Box>
      <DynamicForm
        ref={childRefs}
        fields={courierRegistration}
        userInput={userInput}
        saveData={mockApiCall}
        loadingState={isLoading}
        buttonTitle={ editAction ? "რედაქტირება" : "კურიერის დამატება"}
      />
    </Box>
  );
};

