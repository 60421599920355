export const statuses = [
    {status:6, title:'შეკვეთა მუშავდება'},
    {status:10, title:'დადასტურებული'},
    {status:11, title:'შეკვეთა მზადდება'},
    {status:18, title:'კურიერის მოლოდინში'},
    {status:13, title:'კურიერზე გადაცემული'},
    // {status:19, title:'შეკვეთა გზაშია'},
    {status:14, title:'წარმატებული'},
    {status:8,  title:'კორექტირება მოთხოვნილი'},
    {status:9,  title:'უარყოფილი შეკვეთა'},
    {status:20,  title:'დაწუნებული (კურიერის მიერ)'},
    {status:21,  title:'დაწუნებული (კლიენის მიერ)'},
    {status:22,  title:'ვერ ჩაბარებული'},
    {status:23,  title:'დაბრუნებული'},
]



export const statusesForTakeByMyself = [
    {status:6, title:'შეკვეთა მუშავდება'},
    {status:10, title:'დადასტურებული'},
    {status:11, title:'შეკვეთა მზადდება'},
    {status:14, title:'წარმატებული'},
    {status:8,  title:'კორექტირება მოთხოვნილი'},
    {status:9,  title:'უარყოფილი შეკვეთა'},
    {status:23,  title:'დაბრუნებული'},
]



export const orderFlters = [
    {
      key: "buyer_firstname",
      label: "მყიდველის სახელი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
        key: "buyer_lastname",
        label: "მყიდველის გვარი",
        value: "",
        node: "input",
        inputType: "search",
    },
    {
        key: "buyer_mobile",
        label: "მყიდველის ტელეფონის ნომერი",
        value: "",
        node: "input",
        inputType: "number",
    },
    {
        key: "seller_name",
        label: "გამყიდველის სახელი",
        value: "",
        node: "input",
        inputType: "search",
    },
    {
      key: "seller_lastname",
      label: "გამყიდველის გვარი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      node: "input",
      key: "seller_mobile",
      label: "გამყიდველის ტელეფონის ნომერი",
      value: "",
      inputType: "number",
    },
      {
        node: "input",
        key: "part_number",
        label: "ნაწილის ნომერი",
        value: "",
        inputType: "search",
      },
      {
        node: "selectbox",
        key: "delivery_method",
        label: "მიწოდების ტიპი",
        value: [
            {id:0, label:'მიწოდების ტიპი'},
            {id:"express", label:'ექსპრესს მიწოდება'},
            {id:"standard", label:'სტანდარტული მიწოდება'},
            {id:"takebymyself", label:'საწყობიდან გატანა'},
        ],
        inputType: "search",
      },
      {
        node: "selectbox",
        key: "status",
        label: "სტატუსები",
        value: [
          {id:0, label:'აირჩიე სტატუსი'},
          {id:6, label:'შეკვეთა მუშავდება'},
          {id:10, label:'დადასტურებული'},
          {id:11, label:'შეკვეთა მზადდება'},
          {id:18, label:'კურიერის მოლოდინში'},
          {id:13, label:'კურიერზე გადაცემული'},
          {id:14, label:'წარმატებული'},
          {id:8,  label:'კორექტირება მოთხოვნილი'},
          {id:9,  label:'უარყოფილი შეკვეთა'},
          {id:20,  label:'დაწუნებული (კურიერის მიერ)'},
          {id:21,  label:'დაწუნებული (კლიენის მიერ)'},
          {id:22,  label:'ვერ ჩაბარებული'},
          {id:23,  label:'დაბრუნებული'},
        ],
        inputType: "search",
      },
  ];