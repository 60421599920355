import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import OffersController from "../../../../controller/offers";


const getOfferListData = (page = 1, search) => {
    return OffersController.getOfferList(page, search)
}

export default function useGetOfferList(page, search) {
    const { addToast } = useToasts();
    return useQuery(["offers", page], () => getOfferListData(page, search), {
        // select: (data) => {
        //     const offers = data.data
        //     const last_page = data.last_page
        //     const offerList = offers?.map((offer) => {
        //          return {
        //             request_id: offer.request_id,
        //             expired_at: offer.expired_at,
        //               isOfferMade: offer?.buyer_request_id ? false : true,
        //               id:offer.id,
        //               offer_creation:offer?.created_at,
        //               offer_id:offer?.offer_id ?? offer?.buyer_request_id,
        //               part_name: offer?.part?.title ?? offer?.part?.group_title,
        //               part_number: offer?.part?.vendor_id ?? offer?.part?.number,
        //               buyer_name:`${offer?.buyer.firstname} ${offer?.buyer.lastname}`,
        //               buyer_phone:offer?.buyer.mobile,
        //               seller_name:offer?.seller?.firstname &&  offer?.seller?.lastname ? `${offer?.seller?.firstname}  ${offer?.seller?.lastname}` : null,
        //               seller_phone:offer?.seller?.mobile,
        //               seller_comment:offer?.seller_comment,
        //               buyer_comment:offer?.buyer_comment,
        //               order_details:{
        //                 commission: offer?.commission,
        //                 final_price:offer?.final_price
        //               },
        //               status:offer?.status,
        //               buyer:{
        //                   ...offer?.buyer,
        //                   buyer_comment:offer?.buyer_comment,
        //               },
        //               seller:{
        //                   ...offer?.seller,
        //                   address:offer?.offer?.address,
        //                   personal_id:offer?.offer?.id_number,
        //                   seller_name2:offer?.full_name,      //name which was pointed during making offer
        //                   seller_type:offer?.locaton,
        //                   seller_comment:offer?.seller_comment,
        //                 },
        //               part_info:{
        //                 car:{...offer?.buyerCar?.car, vin_code: offer.buyerCar?.vin_code},
        //                 part:{
        //                     ...offer?.part,
        //                     seller_comment:offer?.seller_comment,
        //                     seller_images:offer.seller_images,
        //                     buyer_comment:offer?.buyer_comment,
        //                     buyer_images:offer.buyer_images,
        //                     warranty:offer?.warranty,
        //                     price:offer?.price,
        //                     cond:offer?.cond,
        //                     grade:offer?.grade,
        //                     preparation_time:offer?.offer?.time, //preparation time
        //                     additional_comment:offer?.offer?.description
        //                 }
        //               }
        //          }
        //     })

            

        //     return {
        //         last_page,
        //         offerList
        //     }
        // },
        onError: (error) => {
            addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 5000,
            });
        },
    });
}