import { useCallback } from "react";
import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import OrderController from "../../../../controller/orders";



const getOrders = (page, search) => {
    const filter = {
        ...search,
        delivery_method:search.delivery_method == 0 ? null : search.delivery_method,
        status:search.status == 0 ? null : search.status,
    }
    return OrderController.getOrderList(page, filter)
}

export default function useGetOrderData(page, search) {
    const { addToast } = useToasts();
    return useQuery(["orders", page], () => getOrders(page, search), {
        select: useCallback((data) => {
             const orderData = data?.data
            //  const orderData = orders?.map((order)=>{
            //      return{
            //          order_id:order.id,
            //          city:order?.city,
            //          order_created_at:order.created_at,
            //          courier:order.courier ? {fullname:`${order.courier?.firstname} ${order.courier?.lastname}`, id:order.courier?.id} : null,
            //          buyerDetails:{
            //              ...order.address,
            //              first_name:order.first_name,
            //              last_name:order.last_name,
            //              mobile:order.mobile,
            //              user_id:order.user_id
            //          },
            //          first_level_delivery:order?.transactionSellers[0]?.delivery_method ? 'Courier' : 'TakeByMyself',  //Check if delivery method exists in first array, if it's null return 'delivery by courier' else 'take from warehouse'
            //          first_level_status:{
            //              status:order.status,
            //              updated_at:order.status_updated_at
            //          },
            //          seller_count:order.transactionSellers.length,
            //          seller:order.transactionSellers
            //         }
            //  })

             const couriers = data?.couriers?.map((courier)=>{
                return {
                    fullname:`${courier.firstname} ${courier.lastname}`,
                    id:courier.id
                }
             })

             return{
                orderData,
                couriers,
                last_page:data?.last_page
             }
             
        },[]),
        onError: (error) => {
            addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 5000,
            });
        },
    });
}