import React, { useCallback } from "react";
import TextField from "@mui/material/TextField";
import { Box, Paper } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";

/* all the values are sting */
interface Ifildes {
  key: string;
  label: string;
  value:
    | {
        id: number | string;
        label: string;
      }[]
    | any;
  node: string;
  inputType: string;
}

interface IFilterProp {
  fields: Ifildes[];
  buttonTitle: string;
  saveData: (data: any) => void;
  loading?: boolean;
  refetch?: () => void;
  setSearch?: React.Dispatch<React.SetStateAction<{}>>;
  display?: string;
}

const DynamicFilter = ({
  fields,
  buttonTitle,
  saveData,
  loading,
  refetch,
  setSearch,
  display,
}: IFilterProp) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const clearFilters = () => {
    reset();
    setSearch({});
    setTimeout(() => {
      refetch();
    }, 500);
  };

  return (
    <form
      style={{ display: display ? display : "none" }}
      onSubmit={handleSubmit((data: any) => saveData(data))}
    >
      <Paper sx={{ p: 2, mb: 4 }} elevation={2}>
        <Box sx={{ flexGrow: 1, paddingY: "10px" }}>
          <Grid container spacing={2} rowSpacing={2}>
            {fields.map((field) => (
              <Grid item xs={3}>
                {field.node == "input" ? (
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      size="medium"
                      inputProps={{ style: { fontSize: "12px" } }}
                      InputLabelProps={{ style: { fontSize: "12px" } }}
                      sx={{ width: "100%" }}
                      id={field.key}
                      type={field.inputType}
                      label={field.label}
                      variant="outlined"
                      {...register(`${field.key}`, {
                        required: false,
                      })}
                      error={errors[`${field.key}`] ? true : false}
                      helperText={
                        errors[`${field.key}`] && (
                          <>{errors[`${field.key}`].message}</>
                        )
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    <Select
                      size="medium"
                      sx={{ fontSize: "12px", width: "100%" }}
                      labelId="demo-select-small"
                      id="demo-select-small"
                      defaultValue={field.value[0].id}
                      label={field.label}
                      {...register(`${field.key}`, {
                        required: false,
                      })}
                      error={errors[`${field.key}`] ? true : false}
                    >
                      {field.value.map((select: any) => (
                        <MenuItem sx={{ fontSize: "12px" }} value={select.id}>
                          {select.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} my={2}>
          <LoadingButton
            sx={{ mx: 2 }}
            loading={false}
            onClick={clearFilters}
            loadingPosition="end"
            endIcon={<ClearIcon />}
            variant="outlined"
          >
            <p style={{ marginTop: "8px" }}>ფილტრაციის გასუფთავება</p>
          </LoadingButton>
          <LoadingButton
            loading={loading}
            type="submit"
            loadingPosition="end"
            endIcon={<SaveIcon />}
            variant="outlined"
          >
            <p style={{ marginTop: "8px" }}>{buttonTitle}</p>
          </LoadingButton>
        </Box>
      </Paper>
    </form>
  );
};

export default DynamicFilter;
