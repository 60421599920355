import React, { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { baseURL } from "../../../../../config/api";


interface IcarBlock {
  carTitle:string, 
  parameters:{
    name:string,
    value:string
  }[], 
  logo:string
}

const CarInfoBlock = ({ carTitle, parameters, logo}:IcarBlock) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  return (
    <Box>
      <Stack spacing={2} direction="row" alignItems={"center"} my={2}>
        <Box
          sx={{
            backgroundColor: "neutral.100",
            height: 50,
            width: 50,
            borderRadius: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
            {logo ? <img src={`${baseURL}storage/${logo}`} height={20} width={20} style={{objectFit:'cover'}} />  : <ImageNotSupportedIcon fontSize="medium" />}
        </Box>
        <Box>
          <Typography variant="body1">{carTitle}</Typography>
        </Box>
        <IconButton onClick={() => setCollapse((prev) => !prev)}>
          {collapse ? (
            <ArrowDropUpIcon fontSize="medium" />
          ) : (
            <ArrowDropDownIcon fontSize="medium" />
          )}
        </IconButton>
      </Stack>
      {collapse ? (
        <>
          {parameters.map((parameter) => (
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <Typography variant="body1">{parameter.name}:</Typography>
              <Typography variant="body1">{parameter.value}</Typography>
            </Stack>
          ))}
        </>
      ) : null}
    </Box>
  );
};

export default CarInfoBlock;
