import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import CourierController from "../../../../controller/couriers";


const deleteCouriers = (id:string|number) => {
    return CourierController.deleteCourier(id)
}


export default function useDeleteCourier(refetch:()=>void) {
    const { addToast } = useToasts();
    return useMutation(deleteCouriers, {
        onSuccess: (data) => {
            addToast(`ოპერაცია წარმატებით შესრულდა!`, {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
            refetch()
        },
        onError: (error) => {
            console.log('sserr');
            addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 5000,
            });
        },
    })
}