/** @format */

import InventorsAPI from "../../config/api/index";

export default class AuthController {
  public static async Login(mobile: number | string, password: string) {
    let data = {
      mobile,
      password,
    };
    const response = await InventorsAPI.post(`/admin/auth`, data);
    return response.data;
  }
}
