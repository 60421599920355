import { Box, Chip, Divider, Typography } from "@mui/material";
import React from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import GridRow from "../../../common/grid-row/grid-row";
import { ChosenEntityTitle } from "../../../../views/customers/offers/helpers";
import PartInfoDetails from "./part-info-details";

interface IpartInfo {
  car: {
    title: string;
    brand: {
      title: string;
    };
    model: {
      title: string;
    };
    parameters: {
      name: string;
      idx: string | number;
      value: string;
    }[];
  };
  part: {
    title: string;
    vendor_id: string | number;
    info: {
      variations: any[];
    };
  };
}

const PartInfo = ({
  partInfo,
  isOfferMade = true,
}: {
  partInfo: any;
  isOfferMade?: boolean;
}) => {
  const car = partInfo.car;

  const renderWarrantyTitle =
    partInfo.warranty == -1 ? "გარანტიის გარეშე" : `${partInfo.warranty} თვე`;
  return (
    <Box>
      {/* car info */}
      <Box mt={3}>
        <Box>
          <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
            <Chip label="ინფორმაცია მანქანის შესახებ" variant="outlined" />
          </Divider>
          <Box maxWidth={500}>
            <GridRow
              keyName="მანქანის მწარმოებელი:"
              children={
                <Chip label={car?.brand?.title} icon={<DirectionsCarIcon />} />
              }
            />
            <GridRow
              keyName="მანქანის მოდელი:"
              children={
                <Chip
                  label={`${car?.model?.title} ${car?.title}`}
                  icon={<DirectionsCarIcon />}
                />
              }
            />
            <GridRow
              keyName="VIN კოდი:"
              children={
                <Chip
                  label={`${partInfo.buyerCar?.vin_code ?? "---"}`}
                  icon={<DirectionsCarIcon />}
                />
              }
            />
          </Box>
          <Box>
            {car?.parameters?.length ? (
              <Box mt={5}>
                <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
                  <Chip label="არჩეული სპეციფიკაციები" variant="outlined" />
                </Divider>
                <Box maxWidth={500}>
                  {car?.parameters.map((spec) => (
                    <GridRow
                      key={spec.idx}
                      keyName={`${spec.name}:`}
                      children={<Chip label={spec.value} />}
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        <Box>
          <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
            <Chip
              label={isOfferMade ? "შეთავაზებული ნაწილი" : "ნაწილი"}
              variant="outlined"
            />
          </Divider>
          <Box maxWidth={500}>
            <GridRow
              keyName="ნაწილის დასახელება"
              children={
                <Chip
                  label={
                    <a
                      href={`https://www.google.com/search?q=${partInfo.name_en}`}
                      target="_blank"
                    >
                      {partInfo.name_en}
                    </a>
                  }
                />
              }
            />

            <GridRow
              keyName="ნაწილის კატეგორია"
              children={[
                partInfo.group.title,
                partInfo.group.hierarchy?.title,
                partInfo.group.hierarchy?.parent?.title,
              ]
                .filter((item) => !!item)
                .reverse()
                .join(" / ")}
            />

            <GridRow
              keyName="ნაწილის ID:"
              children={
                <Chip
                  label={
                    partInfo.id ? (
                      <a
                        href={`https://www.google.com/search?q=${partInfo.id}&sxsrf=ALiCzsbb67z04WhYtIicjlELHZ9JSFInhQ:1659090489519&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiujJza8Z35AhUOgf0HHci5COcQ_AUoAXoECAIQAw&biw=1440&bih=821&dpr=1`}
                        target="_blank"
                      >
                        {partInfo.number}
                      </a>
                    ) : (
                      "--"
                    )
                  }
                />
              }
            />

            {partInfo.cond && (
              <GridRow
                keyName="ნაწილის მდგომარეობა"
                children={<Chip label={ChosenEntityTitle(partInfo.cond)} />}
              />
            )}
            {partInfo.price ? (
              <Box>
                <GridRow
                  keyName="ნაწილის ფასი"
                  children={<Chip label={`${partInfo.price} ლარი`} />}
                />
                {partInfo.grade ? (
                  <GridRow
                    keyName="გრეიდი"
                    children={<Chip label={partInfo.grade} />}
                  />
                ) : null}
                <GridRow
                  keyName="გარანტია"
                  children={<Chip label={renderWarrantyTitle} />}
                />
                <GridRow
                  keyName="ნაწილის მომზადების დრო"
                  children={
                    <Chip label={`${partInfo.preparation_time} საათი`} />
                  }
                />
                <GridRow
                  keyName="გამყიდველის კომენტარი"
                  children={
                    <Typography
                      sx={{ fontSize: 14, mb: 0, mt: 0.5 }}
                      variant="body1"
                      gutterBottom
                    >
                      "{partInfo.seller_comment}"
                    </Typography>
                  }
                />
                <GridRow
                  keyName="დამატებითი კომენტარი (შეთავაზებისას)"
                  children={
                    <Typography
                      sx={{ fontSize: 14, mb: 0, mt: 0.5 }}
                      variant="body1"
                      gutterBottom
                    >
                      "{partInfo.offer?.comment}"
                    </Typography>
                  }
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        <PartInfoDetails part={partInfo.part} />
      </Box>
    </Box>
  );
};

export default PartInfo;
