import { StaffPermissionType } from "../../../../global/interface";

export const tableColumns = [
    { id: "name", label: "სახელი", minWidth: 170 },
    { id: "surname", label: "გვარი", minWidth: 100 },
    {
      id: "mobile",
      label: "ტელეფონის ნომერი",
      minWidth: 250,
      align: "right",
    },
    {
      id: "personal_id",
      label: "პირადი ნომერი",
      minWidth: 170,
      align: "right",
    },
    {
      id: "gmail",
      label: "ელ.ფოსტა",
      minWidth: 170,
      align: "right",
    },
    {
      id: "role",
      label: "ტიპი",
      minWidth: 250,
      align: "right",
    },
    {
      id: "verification_status",
      label: "სტატუსი",
      minWidth: 170,
      align: "right",
    },
    {
      id: "one_time_pass",
      label: "ერთჯერადი პაროლი",
      minWidth: 250,
      align: "right",
    },
    {
      id: "address",
      label: "მისამართი",
      minWidth: 250,
      align: "right",
    },
    {
      id: "registration_date",
      label: "რეგისტრაციის თარიღი",
      minWidth: 250,
      align: "right",
    },
    {
      id: "edit",
      label: "რედაქტირება",
      minWidth: 100,
      align: "right",
    },
    {
      id: "delete",
      label: "წაშლა",
      minWidth: 100,
      align: "right",
    },
  ];





export const staffResgistration = [
    {
      key: "name",
      label: "სახელი",
      value: "",
      node: "input",
      inputType: "text",
      inputValidation: {
        required: {
          value: true,
          message: "ველის მითითება სავალდებულოა!",
        },
      },
    },
    {
      key: "surname",
      label: "გვარი",
      value: "",
      node: "input",
      inputType: "text",
      inputValidation: {
        required: {
          value: true,
          message: "ველის მითითება სავალდებულოა!",
        },
      },
    },
    {
      node: "input",
      key: "mobile",
      label: "ტელეფონის ნომერი",
      value: "",
      inputType: "number",
      inputValidation: {
        required: {
          value: true,
          message: "ველის მითითება სავალდებულოა!",
        },
        pattern: {
          value: /^(0|[1-9]\d*)(\.\d+)?$/,
          message: "შეიყვანეთ მხოლოდ რიცხვები!",
        },
        minLength: {
          value: 9,
          message: "მობილურის უნდა შეიცავდეს 9 სიმბოლოს!",
        },
        maxLength: {
          value: 9,
          message: "მობილურის უნდა შეიცავდეს 9 სიმბოლოს!",
        },
      },
    },
    {
      key: "personal_id",
      label: "პირადი ნომერი",
      value: "",
      node: "input",
      inputType: "text",
      inputValidation: {
        required: {
          value: true,
          message: "ველის მითითება სავალდებულოა!",
        },
        minLength: {
          value: 11,
          message: "პირადი ნომერი უნდა შეიცავდეს 11 სიმბოლოს!",
        },
        maxLength: {
          value: 11,
          message: "პირადი ნომერი  უნდა შეიცავდეს 11 სიმბოლოს!",
        },
      },
    },
    {
      key: "gmail",
      label: "ელ.ფოსტა",
      value: "",
      node: "input",
      inputType: "text",
      inputValidation: {
        required: {
          value: true,
          message: "ველის მითითება სავალდებულოა!",
        },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "ელ.ფოსტის ფორმატირება არასწორია!",
        },
      },
    },
    {
      key: "one_time_pass",
      label: "ერთჯერადი პაროლი",
      value: "",
      node: "input",
      inputType: "text",
      inputValidation: {
        required: {
          value: true,
          message: "ველის მითითება სავალდებულოა!",
        },
        pattern: {
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
          message:
            "პაროლი უნდა შეიცავდეს დიდ-პატარა ასოებს და ციფრებს, მინიმალური სიგრძე 6 სიმბოლო!",
        },
      },
    },
    {
      key: "address",
      label: "მისამართი",
      value: "",
      node: "input",
      inputType: "text",
      inputValidation: {
        required: {
          value: true,
          message: "ველის მითითება სავალდებულოა!",
        },
      },
    },
    {
      node: "selectbox",
      key: "role",
      label: "ტიპი",
      value: [
        { id:8, label: "ოპერატორი" },
        { id:16, label: "ადმინისტრატორი" },
      ],
      inputType: "text",
      inputValidation: {
        required: {
          value: true,
          message: "ველის მითითება სავალდებულოა!",
        },
      },
    },
  ];