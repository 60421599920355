import React from "react";
import Button from "@mui/material/Button";
import {Typography } from "@mui/material";

const ActionButton = ({title, icon, action, bgColor, fontSize}:{title:string, icon:React.ReactNode, action?:()=>void, bgColor?:string, fontSize?:string}) => {
  return (
    <Button
      onClick={action}
      variant="contained"
      size="large"
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: bgColor ? bgColor : "neutral.900",
        ":hover": {
          bgcolor: "secondary.main",
          color: "white",
        },
      }}
      endIcon={icon}
    >
      <Typography variant="body2" fontSize={fontSize ? fontSize :'16px'} sx={{ marginTop: "7px" }} py={0.5}>
         {title}
      </Typography>
    </Button>
  );
};

export default ActionButton;
