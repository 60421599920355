/** @format */

import { createSlice } from "@reduxjs/toolkit";


interface IuserAuth {
  user: { 
    idToken: string, 
    expires: string | Date | number, 
    name: string, 
    adminRole: number | null 
    userSurname:string
  }
}

const initialState: IuserAuth = {
  user: { 
    idToken: '', 
    expires: '', 
    name: '', 
    adminRole: null,
    userSurname:''
  }
}

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo(state, action) {
      let user = action.payload;
      state.user = { ...user };
    },
  },
});

export const { setUserInfo } = UserSlice.actions;

export default UserSlice.reducer;
