import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BlockIcon from "@mui/icons-material/Block";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import StarsIcon from "@mui/icons-material/Stars";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ActionButton from "../../../common/buttons/action-button";

const SellerRatingDetails = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} rowSpacing={5}>
        {/* rating precent */}
        <SellerRateRow value={"რეიტინგი"} icon={<ThumbsUpDownIcon />} />
        <SellerRateRow value={"108%"} />
        {/*positiove rate*/}
        <SellerRateRow value={"დადებითი შეფასება"} icon={<ThumbUpIcon />} />
        <SellerRateRow value={"15"} />
        {/*negative rate*/}
        <SellerRateRow
          value={"უარყოფითი შეფასება"}
          icon={<ThumbDownAltIcon />}
        />
        <SellerRateRow value={"11"} />
        {/*orders*/}
        <SellerRateRow
          value={"შეკვეთების რაოდენობა"}
          icon={<SummarizeIcon />}
        />
        <SellerRateRow value={"201"} />
        {/*success orders*/}
        <SellerRateRow
          value={"წარმატებული შეკვეთები"}
          icon={<CheckCircleIcon />}
        />
        <SellerRateRow value={"201"} />
        {/*failded orders*/}
        <SellerRateRow
          value={"უკან დაბრუნებული შეკვეთები"}
          icon={<ChangeCircleIcon />}
        />
        <SellerRateRow value={"11"} />
        {/*blocked orders*/}
        <SellerRateRow value={"გაუქმებული შეკვეთები"} icon={<BlockIcon />} />
        <SellerRateRow value={"11"} />

        {/* missed orders */}
        <SellerRateRow
          value={"ვერ ჩაბარებული შეკვეთები"}
          icon={<PhoneMissedIcon />}
        />
        <SellerRateRow value={"11"} />

        {/* manual rate number */}
        <Grid item xs={6}>
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">
              ხელით მითითებული
            </InputLabel>
            <Input
              defaultValue={0}
              type="number"
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <StarsIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
           <ActionButton title="შენახვა" icon={<SaveIcon/>}/>
        </Grid>
      </Grid>
    </Box>
  );
};

const SellerRateRow = ({ value, icon }) => {
  return (
    <Grid item xs={6}>
      <Box display={"flex"} alignItems="center" sx={{ width: "300px" }}>
        <Box mr={1}>{icon}</Box>
        <Typography variant="body1" sx={{ width: "100%" }}>
          {value}
        </Typography>
      </Box>
    </Grid>
  );
};

export default SellerRatingDetails;
