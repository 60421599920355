import React from 'react'
import Map from "@mui/icons-material/Map";
import { IconButton } from '@mui/material';

const LocationMap = ({location}) => {
  return (
    <IconButton onClick={()=>window.open(`https://maps.google.com/?q=41.715137,44.827095`, '_blank').focus()}>
        <Map/>
    </IconButton>
  )
}

export default LocationMap