import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, TableCell, TableRow } from "@mui/material";
import _ from "lodash";
import React from "react";
import {
  IColumn,
  IOfferRow,
} from "../../../../../views/customers/offers/interface";

type Props = {
  row: IOfferRow;
  columns: IColumn[];
  isExpanded: boolean;
  toggleExpand: () => void;
};

export function BuyerRow({ row, columns, isExpanded, toggleExpand }: Props) {
  return (
    <TableRow style={{ backgroundColor: "#EBEDEF" }}>
      <TableCell style={{ minWidth: 100, maxWidth: 100, width: 100 }}>
        <IconButton aria-label="expand row" size="small" onClick={toggleExpand}>
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>

      {columns.map((column) => {
        const value = _.get(row, column.id);

        return (
          <TableCell
            key={column.id}
            align="center"
            sx={{ fontSize: "13px" }}
            style={column.style ?? {}}
          >
            {column.render ? column.render(value, row) : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
