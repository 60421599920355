import { IconButton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import React, { useRef, useState } from "react";

export default function ViaroInput({
  label,
  defaultValue,
  isEditable,
  setEditInfo,
  idvalue
}: {
  label: string;
  defaultValue: string;
  isEditable?: boolean;
  setEditInfo:any
  idvalue:string
}) {
  const [disabled, setDisabled] = useState(true);
  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        backgroundColor: "#F8F8F8",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        onChange={(e)=>setEditInfo((prev)=>({...prev, [`${idvalue}`]:e.target.value}))}
        autoFocus
        InputProps={{ disableUnderline: true }}
        sx={{
          input: {
            color: "#231F20",
            background: "#F8F8F8",
            borderBottom: "none",
            paddingLeft: 0,
            fontSize: "13px",
            overflowWrap: "break-word",
            "-webkit-text-fill-color": `${'unset'} !important`
          },

          label: {
            fontSize: "12px",
            left: -10,
            top: -8,
            color: "viaro.green",
          },
        }}
        fullWidth
        disabled={disabled}
        label={label}
        defaultValue={defaultValue}
        id="filled-required"
        variant="filled"
      />
      {isEditable ? (
        <IconButton
          sx={{ background: "#ffffff", borderRadius: 3 }}
          onClick={() => setDisabled((prev) => !prev)}
        >
          <EditIcon />
        </IconButton>
      ) : null}
    </Box>
  );
}
