/** @format */

import { Box, Grid } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonIcon from "@mui/icons-material/Person";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import InfoBox from "../../components/dashboard/main-page/info-box/info-box";
import MainPageController from "../../controller/main-page/index";
import { BackdropLoader } from "../../components/common/backdrop-loader/backdrop-loader";
import { CarInfo } from "../../components/dashboard/main-page/car-info/car-info";


const Main = () => {
  const [userInfo, setUserInfo] = useState<{sellers:number,total:number, buyers:number}>({sellers:0, buyers:0,total:0});
  const { addToast } = useToasts();
  const getUserCount = () => {
    return MainPageController.getUserCount();
  };

  const { isFetching } = useQuery("mainPage", getUserCount, {
    onSuccess: (data) => {
      const users = data[0];
      setUserInfo({ ...users });
    },
    onError: (error) => {
      addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    },
  });

  if (isFetching) {
    return <BackdropLoader />;
  }
  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 2 }}>
     {/*  <lottie-player src={JSON.stringify(require('../../global/lottie/not-found.json'))} mode="bounce" background="transparent"  speed="1"  loop  autoplay></lottie-player> */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <InfoBox
              title={"მომხმარებელთა საერთო რაოდენობა"}
              qty={userInfo.total}
              icon={<GroupIcon />}
            />
          </Grid>
          <Grid container spacing={1} sx={{marginTop:'10px'}}>
          <Grid item xs={6}>
            <InfoBox
              title={"მყიდველი"}
              qty={userInfo.buyers}
              icon={<PersonIcon />}
            />
          </Grid>
          <Grid item xs={6}>
            <InfoBox
              title={"გამყიდველი"}
              qty={userInfo.sellers}
              icon={<StorefrontIcon />}
            />
          </Grid>
          </Grid>
        </Grid>
        </Grid>
       <Grid item xs={6}>
           <CarInfo/>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Main;
