import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { Box, IconButton, Typography } from "@mui/material";


interface ImodalProps{
  status:boolean, 
  maxWidth?:any, 
  title?:string, 
  children?:React.ReactNode, 
  setClose?:()=>void
}



const ViaroModal = ({status, maxWidth, title, children, setClose}:ImodalProps) => {
  return (
    <div>
      <Dialog
        onClose={setClose}
        open={status}
        maxWidth={maxWidth}
        keepMounted={false}
        fullWidth={true}
        aria-describedby="alert-dialog-slide-description"
       
      >
        <DialogTitle>
           <Box display={'flex'} justifyContent={'space-between'}>
             <Box component="p" marginTop={1}>{title}</Box>
             <IconButton onClick={setClose}>
                  <CloseRounded/>
             </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent  sx={{padding:0}}>
          <DialogContentText id="alert-dialog-slide-description" >
            {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViaroModal;
