import { CircularProgress, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import useChangeStatus from "../../../views/customers/orders/hooks/useChangeStatus";
import { orderType } from "../../../views/customers/orders/interfaces";
import Dot from "../../common/dot/dot";
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/ka";

interface IofferStatus {
  statusUpdatedAt?: number;
  currentStatus: number;
  level?: orderType;
  id?: string | number;
  statuses: { status: number; title: string }[];
  loading?: boolean;
  refetch:()=>void
}

export default function OrderStatus({
  currentStatus,
  statuses,
  statusUpdatedAt,
  id,
  level,
  loading,
  refetch
}: IofferStatus) {
  const [status, setStatus] = useState(currentStatus);
  const { spinner, changeOrderStatus } = useChangeStatus();

  useEffect(() => {
    setStatus((prev) => currentStatus);
  }, [currentStatus, loading]);

  const renderBadge = useCallback((status: any) => {
    if (status == 6) {
      return <Dot bgColor="#E7041C" />;
    } else if (status == 18 || status == 19 || status == 11 || status == 8 || status == 13) {
      return <Dot bgColor="#FCC300" />;
    }else if(status == 14){
      return <Dot bgColor="#00DC84" /> 
    }
    else if(status == 9){
      return <Dot bgColor="#E7041C" />;
    }else{
      return <Dot bgColor="#E7041C" />;
    }
  }, []);



  const changeStatus = (e) => {
    setStatus(e.target.value);
    changeOrderStatus(level, id, e.target.value).then(()=>{
      refetch()
    });
  };


  const StatusUpdatetime =  moment.unix(statusUpdatedAt) 
  

  return (
    <>
      {spinner ? (
        <CircularProgress/>
      ) : (
        <Box position="relative">
           <Select
          sx={{ fontSize: "10px", fieldset:{
            borderColor:'unset',
            border:'none'
          }}}
          labelId="demo-select-small"
          id="demo-select-small"
          value={status}
          size="small"
          onChange={(e) => changeStatus(e)}
        >
          {statuses.map((el) => (
            <MenuItem
              key={el.status + Date.now()}
              sx={{ fontSize: "10px" }}
              value={el.status}
            >
              <Box display="flex" alignItems="center" sx={{ fontSize: "10px" }}>
                <Box mr={1} mb={0.5}>
                  {renderBadge(el.status)}
                </Box>
                {el.title}
              </Box>
            </MenuItem>
          ))}
        </Select>
          {statusUpdatedAt !== 0  ?
            <Box display="flex" justifyContent="center">
              <Moment locale="ka" format="LLL" style={{fontSize:'10px'}}>
              {StatusUpdatetime}
              </Moment>
           </Box> : null}
        </Box>
      )}
    </>
  );
}

