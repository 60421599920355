import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import OffersController from "../../../../../controller/offers";

type StatusValueObject = {
  statusId: number;
  title: string;
  time: number;
};

const statuses: StatusValueObject[] = [
  { statusId: 1, title: "მიმდინარე", time: 0 },
  { statusId: 2, title: "ელოდება მომხმარებლის პასუხს", time: 0 },
  { statusId: 3, title: "პასუხის გაცემის დრო ამოიწურა(24სთ)", time: 0 },
  { statusId: 4, title: "შეთავაზების დრო ამოიწურა(40წთ)", time: 0 },
  { statusId: 5, title: "არ მაქვს მარაგში", time: 0 },
  { statusId: 6, title: "მუშავდება(10 წთ)", time: 0 },
  { statusId: 8, title: "კორექტირება მოთხოვნილი", time: 0 },
  { statusId: 9, title: "უარყოფილი შეკვეთა", time: 0 },
  { statusId: 10, title: "დადასტურებული შეკვეთა", time: 0 },
  { statusId: 11, title: "შეკვეთა მზადდება", time: 0 },
  { statusId: 13, title: "კურიერზე გადაცემული", time: 0 },
  { statusId: 14, title: "წარმატებული", time: 0 },
  { statusId: 18, title: "კურიერის მოლოდინში", time: 0 },
  { statusId: 20, title: "დაწუნებული (კურიერის მიერ)", time: 0 },
  { statusId: 21, title: "დაწუნებული (კლიენტის მიერ)", time: 0 },
  { statusId: 22, title: "ვერ ჩაბარებული", time: 0 },
  { statusId: 23, title: "დაბრუნებული", time: 0 },
];

const statusById = statuses.reduce<
  Record<number, StatusValueObject | undefined>
>((record, status) => {
  record[status.statusId] = status;
  return record;
}, {});

type Props = {
  data: {
    statusId?: number;
    id: number;
    timeRemainig?: number;
  };
};

export function StatusSelector({ data }: Props) {
  const { addToast } = useToasts();

  const status = useMemo(() => {
    if (!data.statusId) return null;
    const status = statusById[data.statusId];
    return status;
  }, [data]);

  const [selectedStatus, setSelectedStatus] = useState<StatusValueObject>();

  const timeoutRef = useRef<NodeJS.Timeout>();
  const handleStatusChange = useCallback((event: SelectChangeEvent<number>) => {
    const statusId = parseInt(event.target.value + "");
    if (statusId < 0) return;

    const selectedStatus = statusById[statusId]
    if (!selectedStatus) return;

    setSelectedStatus(selectedStatus);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      refetch();
    }, 1000);
  }, []);

  const { isFetching, refetch } = useQuery(
    ["changeStatus", selectedStatus],
    () => {
      if (!selectedStatus) return;
      OffersController.changePartStatus(selectedStatus.statusId, data.id);
    },
    {
      enabled: false,
      onError: (error) => {
        addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
    }
  );

  if (isFetching) {
    return <CircularProgress size={40} />;
  }

  return (
    <Select
      sx={{ fontSize: "10px" }}
      value={selectedStatus?.statusId ?? status?.statusId}
      title={selectedStatus?.title ?? status?.title}
      size="small"
      label="სტატუსის არჩევა"
      onChange={handleStatusChange}
    >
      {statuses.map((el) => (
        <MenuItem key={el.statusId} sx={{ fontSize: "9px" }} value={el.statusId}>
          <Box display="flex" alignItems="center" sx={{ fontSize: "9px" }}>
            {el.title}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

//მიმდინარე თავისი ტაიმერით 40 წუთი, გაგზავნილი 24 ველოდებით მომხმარებელს, 24 საათი ამოიწრუად არ შეძენილი დეაქტივირებული, დრო ამოეწურა 40 წუთიან ტაიმერს გამორჩენილი, 40 წუთიანი არ მაქვს მარაგში, დასადასტურებელი მომლოდინე შეკვეთა მუშავდება 10 წუთუანი ტაიმერი, კორექტირების მოთხოვნა, კორექტირებული, უარყოფილი გაუქმებული შეკვეთა, დადასტურებული შეკვეთა კურიერის მოლოდინში, შეკვეთა მზადდება, დაქენსელებული დაწუნებული, კურიერზე გადაცემული, წარმატებული დასრულებული ჩაბარებული, დასაბრუნებელი, დაბრუნებული, გაუქმება ნეიტრალური სტატუსით
