import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import { DeliveryMethods } from "../interfaces";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";

export const useRenderDeliveryMethod = (deliveryType: DeliveryMethods, city:string) => {
  switch (deliveryType) {
    case DeliveryMethods.express:
      return (
        <Tooltip title="Express Delivery" arrow placement="top-end">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={require("../../../../images/express.png")} />
            <Box component={"p"} ml={0.5}>
               {city?.toUpperCase()}
            </Box>
          </Box>
        </Tooltip>
      );
      break;
    case DeliveryMethods.standart:
      return (
        <Tooltip title="Standart Delivery" arrow placement="top-end">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={require("../../../../images/standart.png")} />
            <Box component={"p"} ml={0.5}>
               {city?.toUpperCase()}
            </Box>
          </Box>
        </Tooltip>
      );
      break;
    default:
      return (
        <Tooltip title="საწყობიდან გატანა" arrow placement="top-end">
            <Box display="flex" alignItems="center" >
                <Box sx={{height:20, width:20, backgroundColor:'viaro.green', display:'flex',textAlign:'center', justifyContent:'center', alignItems:'center', borderRadius:50}}>
                    <DirectionsWalkIcon sx={{fontSize:15}} />
                </Box>
                <Box component={'p'} sx={{ml:'7px', fontSize:'12px', mt:'4px'}}>{deliveryType}</Box>
                <Box component={'p'} sx={{ml:'7px', fontSize:'12px', mt:'4px'}}>{city?.toUpperCase()}</Box>
            </Box>
        </Tooltip>
      );

      break;
  }
};

enum Delivery {
  courier = "Courier",
  takeByMySelf = "TakeByMyself",
}

export default function RenderDelivery(delivery, city:string) {
  if (Delivery.courier == delivery) {
    return (
        <Tooltip title="მიწოდება კურიერით" arrow placement="top-end">
            <Box display="flex" alignItems="center" >
                    <Box sx={{height:20, width:20, backgroundColor:'viaro.green', display:'flex',textAlign:'center', justifyContent:'center', alignItems:'center', borderRadius:50}}>
                        <DeliveryDiningIcon sx={{fontSize:15}} />
                    </Box>
                    <Box component={'p'} sx={{ml:'7px', fontSize:'12px', mt:'4px'}}>{city?.toUpperCase()}</Box>
            </Box>
       </Tooltip>
    );
  } else {
    return (
        <Tooltip title="საწყობიდან გატანა" arrow placement="top-end">
            <Box display="flex" alignItems="center" >
                <Box sx={{height:20, width:20, backgroundColor:'viaro.green', display:'flex',textAlign:'center', justifyContent:'center', alignItems:'center', borderRadius:50}}>
                    <DirectionsWalkIcon sx={{fontSize:15}} />
                </Box>
                <Box component={'p'} sx={{ml:'7px', fontSize:'12px', mt:'4px'}}>{city?.toUpperCase()}</Box>
            </Box>
        </Tooltip>
    )
  }
}
