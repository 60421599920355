import { Box, Chip, Divider } from '@mui/material'
import React from 'react'
import { entityStatusTitle, warrantyStatusTitle } from '../../../global/helper'
import GridRow from '../../common/grid-row/grid-row'
import ViaroModal from './viaro-modal'
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

interface IdetailsModal {
    detailsModal:boolean,
    setDetailsModal:any
    data:any
}


export default function PartdetailsModl({detailsModal, setDetailsModal, data}:IdetailsModal) {
  console.log(data);
  
  
  return (
    <ViaroModal title="ნაწილის დეტალები" status={detailsModal} setClose={()=>setDetailsModal({status:false})} maxWidth='md'>
    <Box display="flex" alignItems='center' ml={1.5} justifyContent="flex-start">
              {/* about part */}
              <Box display="flex" mt={3}>
                <Box mr={3}>
                    <img src={data.part?.group_img} style={{height:200, width:200, borderRadius:'4px', objectFit:'cover', border:'1px solid gray',margin:'0 10px'}}/>
                </Box>
                <Box pb={3}>
                    <Box component="p" fontWeight='bold' color="#000">{data.part.title}</Box>
                    <Box display="flex" alignItems='center' mt={0.5}>
                        <Box component="p" color="viaro.green" fontWeight="bold">{data.part.number}</Box>
                        <Box component="p" mx={1}>•</Box>
                        <Box component="p" fontWeight="lighter">{data.part.title}</Box>
                    </Box>
                    <Box display="flex" alignItems='center' mt={0.5}>
                        <Box component="p">{entityStatusTitle(data.offerPart.cond)}</Box>
                        <Box component="p" mx={1}>•</Box>
                        <Box component="p" fontWeight="lighter">{data.offerPart.grade}</Box>
                        <Box component="p" fontWeight="lighter">{warrantyStatusTitle(data.offerPart.warranty)}</Box>
                    </Box>
                    {data.part.description ?                     
                    <Box mt={3}>
                        <Box component="p" fontWeight='bold' color="#000" pb={1}>ნაწილის აღწერა</Box>
                        <Box component="p" sx={{overflowWrap: 'break-word', fontSize:'12px'}}>
                           {data.part.description}
                        </Box>
                     </Box> : null}
                    <Box mt={3}>
                        <Box component="p" fontWeight='bold' color="#000" pb={1}>Grade აღწერა</Box>
                        <Box component="p" sx={{overflowWrap: 'break-word', fontSize:'12px'}}>
                           {data.offerPart.seller_comment ?? 'არ არის მითითებული'}
                        </Box>
                     </Box>
                     <Box mt={3}>
                        <Box component="p" fontWeight='bold' color="#000" pb={1}>მყიდველის კომენტარი</Box>
                        <Box component="p" sx={{overflowWrap: 'break-word', fontSize:'12px'}}>
                           {data.offerPart.buyer_comment ?? 'არ არის მითითებული'}
                        </Box>
                     </Box>
                     <Box mt={3}>
                        <Box component="p" fontWeight='bold' color="#000" pb={1}>დამატებითი კომენტარი (შეთავაზებისას)</Box>
                        <Box component="p" sx={{overflowWrap: 'break-word', fontSize:'12px'}}>
                           {data.additional_comment ?? 'არ არის მითითებული'}
                        </Box>
                     </Box>
                     <Box mt={3}>
                    <Box>
                      <Box maxWidth={500}>
                        <GridRow
                          keyName="მანქანის მწარმოებელი:"
                          children={
                            <Chip label={data.part.car?.brand?.title} icon={<DirectionsCarIcon />} />
                          }
                        />
                        <GridRow
                          keyName="მანქანის მოდელი:"
                          children={
                            <Chip
                              label={`${data.part.car?.model.title} ${data.part.car?.title}`}
                              icon={<DirectionsCarIcon />}
                            />
                          }
                        />
                      </Box>
                      <Box>
                        {data.part.car?.parameters.length ? (
                          <Box mt={5}>
                            <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
                              <Chip label="არჩეული სპეციფიკაციები" variant="outlined" />
                            </Divider>
                            <Box maxWidth={500}>
                              {data.part.car?.parameters?.map((spec) => (
                                <GridRow
                                  key={spec.idx}
                                  keyName={`${spec.name}:`}
                                  children={<Chip label={spec.value} />}
                                />
                              ))}
                            </Box>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                     <Box display="flex" mt={3}>
                     <Box>
                        <Box component="p" fontWeight='bold' color="#000" pb={1}>ფასი</Box>
                        <Box component="p" color="viaro.green" textAlign="center">
                          {data.offerPart.price} ლ
                        </Box>
                    </Box>
                    {/* <Box mx={2}>
                        <Box component="p" fontWeight='bold' color="#000"  pb={1}>მიტანა</Box>
                        <Box component="p" color="viaro.green" textAlign="center">
                          1222 ლ
                        </Box>
                    </Box> */}
                    {/* <Box mx={2}>
                        <Box component="p" fontWeight='bold' color="#000" pb={1}>საკომისიო</Box>
                        <Box component="p" color="viaro.green" textAlign="center">
                          1422 ლ
                        </Box>
                    </Box> */}
                    {/* <Box mx={2}>
                        <Box component="p" fontWeight='bold' color="#000" pb={1}>ჯამური ფასი</Box>
                        <Box component="p" color="viaro.green" textAlign="center">
                          1234 ლ
                        </Box>
                    </Box> */}
                  </Box>
                </Box>
              </Box>
          </Box>
    </ViaroModal>
  )
}
