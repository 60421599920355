import React from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";


const InfoBox = ({ title, icon, qty }:{title:string, icon:React.ReactNode, qty:number}) => {
  return (
    <Box>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1">{title}</Typography>
          <Box mx={2} borderRadius={"50%"} display={'flex'} justifyContent={'center'} alignItems={'center'} p={2}>
            {icon}
          </Box>
        </Box>
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 3,
            width: "100%",
          }}
        />
        <Box>
          <Typography variant="h2">{qty}</Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default InfoBox;
