import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import React from "react";
import ViaroInput from "./viaro-input";
import ViaroModal from "./viaro-modal";

export default function SellerDetails({modal,  setModal, userdata, setUserData, editUserInfo}:{modal:boolean,  setModal:any, editUserInfo:()=>void,userdata:any, setUserData:any}) {
  return (
    <ViaroModal
      status={modal}
      title="გამყიდველის დეტალები"
      maxWidth="xs"
      setClose={() => setModal({status:false, currentUserId:'', offerId:""})}
    >
      <Box mb={0.5}>
        <ViaroInput
          setEditInfo={setUserData}
          label="სახელი"
          idvalue="name"
          defaultValue={userdata.name}
          isEditable={true}
        />
      </Box>
      <Box mb={0.5}>
        <ViaroInput
          setEditInfo={setUserData}
          label="გვარი"
          idvalue="surname"
          defaultValue={userdata.surname}
          isEditable={true}
        />
      </Box>
      <Box mb={0.5}>
        <ViaroInput
          setEditInfo={setUserData}
          label="ტელეფონის  ნომერი"
          idvalue="phone"
          defaultValue={userdata.phone}
        />
      </Box>
      {userdata.juridical_name ?       
      <Box mb={0.5}>
        <ViaroInput
          setEditInfo={setUserData}
          label="შპს დასახელება"
          idvalue="juridical_name"
          defaultValue={userdata.juridical_name}
          isEditable={true}
        />
      </Box> : null}

      <Box mb={0.5}>
        <ViaroInput
          setEditInfo={setUserData}
          label="მისამართი"
          idvalue="address"
          defaultValue={userdata.address}
          isEditable={false}
        />
      </Box>
      <Box mb={0.5}>
        <ViaroInput
          setEditInfo={setUserData}
          label="საიდენტიფიკაციო კოდი"
          idvalue="identify_code"
          defaultValue={userdata.identify_code}
          isEditable={true}
        />
      </Box>
      <Box mb={0.5}>
        <ViaroInput
          setEditInfo={setUserData}
          label="ანგარიშის ნომერი"
          idvalue="iban"
          defaultValue={userdata.iban}
          isEditable={true}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "center",
          py: 2,
        }}
      >
        <LoadingButton
          onClick={editUserInfo}
          sx={{
            backgroundColor: "#D9D9D9",
            color: "#000",
            border: "1px solid #000",
            py: 1.5,
            px: 10,
          }}
          variant="outlined"
        >
          რედაქტირება
        </LoadingButton>
      </Box>
    </ViaroModal>
  );
}
