import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import OrderController from "../../../../controller/orders";


export default function useEditUserInfo(){
    const { addToast } = useToasts();
    const EditUserData = async (type, id, uid, data)=>{
        try{
            const response = await OrderController.changeUserData(type, id,uid,data)
            addToast(`მონაცემები წარმატებით განახლდა!`, {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 5000,
            });
        }catch(err){
           addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
            });
        }
    }

    return {EditUserData}
}