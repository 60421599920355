export enum DeliveryMethods {
   express = 'express',
   standart = 'standard'
}


export enum orderType {
   transaction = "transaction",
   part = "part",
   seller = "seller"
 }