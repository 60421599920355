import { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import OrderController from "../../../../controller/orders";
import { orderType } from "../interfaces";

const changeStatus = (
  type?: orderType,
  id?: string | number,
  status?: number
) => {
  return OrderController.changeOrderStatus(type, id, status);
};

export default function useChangeStatus() {
  const { addToast } = useToasts();
  const [spinner, setSpinner] = useState(false)
  const changeOrderStatus = useCallback(async (type?: orderType,id?: string | number,status?: number) => {
    setSpinner(true)
    try {
      const res = await changeStatus(type, id, status);
    } catch (err) {
      addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    }finally{
      setSpinner(false)
    }
  }, []);

  return {spinner, changeOrderStatus}
}
