import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Chip } from "@mui/material";
import { StaffPermissionType } from "../../../../global/interface";
export const StaffPermissions = ({ value} : {value:number}) => {
  const [veifyStatus, setVerifyStatus] = useState(value);
  return (
    <Chip color={veifyStatus == StaffPermissionType.OPERATOR ? "primary" : "success" }  label={veifyStatus == StaffPermissionType.OPERATOR ? 'ოპერატორი' : 'ადმინისტრატორი'} size="medium" />
  );
};

