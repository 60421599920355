import { Box, Button, Modal } from "@mui/material";
import React, { useState } from "react";
import DynamicFilter from "../../../components/common/dynamic-filter/dynamic-filter";
import SlideInModal from "../../../components/common/slide-in-modal/slide-in-modal";
import MyDataTable from "../../../components/common/table/myDataTable";
import { AddSellers } from "../../../components/dashboard/sellers/add-sellers/add-sellers";
import { sellerFilter } from "./helpers/filter";
import SellerDetails from "./sellerDetails";

const Sellers = () => {
  const [openAddSellerModal, setOpenAddSellerModal] = useState(false);
  const [filter, setFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState<number | null>(null);
  const handleOpen = (id) => {
    setSelectedPromo(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const callFilterApi = (data) => {
    console.log(data);
  };
  return (
    <>
      <Box sx={{ py: 3, px: 2 }}>
        {/* <Box display={"flex"} justifyContent={"flex-end"} pb={2}>
          <Box mx={3}>
            <ActionButton
              title="ფილტრები"
              icon={filter ? <CloseRounded /> : <ManageSearch />}
              action={() => setFilter((prev) => !prev)}
            />
          </Box>
          <ActionButton
            title="გამყიდველის რეგისტრაცია"
            icon={<AddCircle />}
            action={() => setOpenAddSellerModal(true)}
          />
        </Box> */}
        <Box>
          {filter ? (
            <DynamicFilter
              fields={sellerFilter}
              saveData={callFilterApi}
              buttonTitle={"ძებნა"}
            />
          ) : null}
        </Box>
        <MyDataTable
          url="/admin/users/2"
          columns={[
            { id: "id", label: "ID" },
            { id: "firstname", label: "სახელი", minWidth: 170 },
            { id: "lastname", label: "გვარი", minWidth: 100 },
            {
              id: "mobile",
              label: "ტელეფონის ნომერი",
              minWidth: 170,
              align: "right",
            },
            {
              id: "created_at",
              label: "წაშლა",
              minWidth: 250,
              align: "right",
            },
            {
              label: "",
              align: "right",
              render: (data) => (
                <Button variant="contained" onClick={() => handleOpen(data.id)}>
                  Cars
                </Button>
              ),
            },
          ]}
        />
        <SlideInModal
          status={openAddSellerModal}
          title="გამყიდველის დამატება"
          maxWidth="lg"
          setClose={() => setOpenAddSellerModal(false)}
        >
          <AddSellers />
        </SlideInModal>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        onBackdropClick={handleClose}
        sx={{ overflow: "scroll", m: 4 }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SellerDetails url={"/admin/users/info/" + selectedPromo} />
      </Modal>
    </>
  );
};

export default Sellers;
