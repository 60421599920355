export const sellerRateFilter = [
    {
      key: "name",
      label: "სახელი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      key: "surname",
      label: "გვარი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      node: "input",
      key: "mobile",
      label: "ტელეფონის ნომერი",
      value: "",
      inputType: "search",
    },
    {
        node: "input",
        key: "rating",
        label: "რეიტინგი",
        value: "",
        inputType: "number",
    },
  ];