import React, { useCallback } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, IconButton, Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Moment from "react-moment";
import "moment/locale/ka";
import { UserVerifyStatus } from "../../dashboard/sellers/user-verify-status/user-verify-status";
import NotificationStatus from "../../dashboard/sellers/notification-status/notification-status";
import LocationMap from "../../dashboard/sellers/location-map/location-map";
import ActionButton from "../buttons/action-button";
import { StaffPermissions } from "../../dashboard/staff/staff-permissions/staff-permissions";
import PasswordValue from "../table-buttons/password-value/password-value";


interface Itable {
  tableColumns:any[],
  tableRows:any[],
  action?:(param?:any)=>void,
  editRow:React.Dispatch<React.SetStateAction<{modalStatus: boolean; info: {}}>>,
  deleteAction:(id:string|number)=>void
}

export default function DynamicTable({tableColumns, tableRows, action, editRow, deleteAction}:Itable) {
  const renderTableRowValue = useCallback((columnType, value, row) => {
    const components = {
      notification: <NotificationStatus status={value} />,
      verification_status: <UserVerifyStatus value={value} rowData={row} />,
      default_address: (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          {value}
          <LocationMap />
        </Stack>
      ),
      details: (
        <ActionButton
          title={"დეტალები"}
          icon={<InfoIcon />}
          action={() => action(row.sellerId)}
        />
      ),
      rating: (
        <IconButton sx={{ fontSize: "15px" }} onClick={action}>
          {value}
        </IconButton>
      ),
      role: <StaffPermissions value={value} />,
      edit: (
        <IconButton
          sx={{ fontSize: "15px" }}
          onClick={() => editRow({ modalStatus: true, info: row })}
        >
          <EditIcon />
        </IconButton>
      ),
      delete: (
        <IconButton
          sx={{ fontSize: "15px" }}
          onClick={() => deleteAction(row.id)}
        >
          <DeleteIcon style={{ color: "#D14343" }} />
        </IconButton>
      ),
      registration_date: (
        <Moment locale="ka" format="LLL">
          {value}
        </Moment>
      ),
      one_time_pass:(
         <PasswordValue password={value}/>
      ),
      // auto_type:(
      //   <Box>asdasd</Box>
      // )
    };

    if (components.hasOwnProperty(columnType)) {
      return components[columnType];
    } else {
      return value;
    }
  }, []);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 480 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  style={{
                    minWidth:"90px",
                    maxWidth:"110px",
                    fontSize: "13px",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {tableColumns.map((column) => {
                    const value = row[column.id];
                    const columnName = column.id;
                    return (
                      <TableCell key={column.id} align="center">
                        {renderTableRowValue(columnName, value, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
