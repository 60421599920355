import { StaffPermissionType } from "../../../../global/interface";



export const staffFilter = [
    {
      key: "name",
      label: "სახელი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      key: "surname",
      label: "გვარი",
      value: "",
      node: "input",
      inputType: "search",
    },
    {
      node: "input",
      key: "mobile",
      label: "ტელეფონის ნომერი",
      value: "",
      inputType: "search",
    },
    {
      node: "input",
      key: "address",
      label: "მისამართი",
      value: "",
      inputType: "search",
    },
    {
      node: "input",
      key: "personal_id",
      label: "პირადი ნომერი",
      value: "",
      inputType: "number",
    },
    {
      node: "input",
      key: "gmail",
      label: "ელ.ფოსტა",
      value: "",
      inputType: "search",
    },
    {
      node: "selectbox",
      key: "verification_status",
      label: "ვერიფიკაციის სტატუსი",
      value: [
          { id: 1, label: "ვერიფიცირებული" },
          { id: 2, label: "დარეგისტრირებული" },
          { id: 3, label: "დაარეგისტრირა ინვენტორსმა" },
          { id: 4, label: "დაბლოკილი" },
      ],
      inputType: "search",
    },
    {
        node: "selectbox",
        key: "role",
        label: "ვერიფიკაციის სტატუსი",
        value: [
            { id: StaffPermissionType.OPERATOR, label: "ოპერატორი" },
            { id: StaffPermissionType.ADMINISTRATOR, label: "ადმინისტრატორი" },
        ],
        inputType: "search",
      },
  ];