import { Box } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import StaffController from "../../../../controller/staff";
import { staffResgistration } from "../../../../views/customers/staff/helpers/dynamicValues";
import DynamicForm from "../../../common/dynamic-form/dynamic-form";

interface IinitalProps {
  mobile: string;
  name: string;
  surname: string;
  personal_id: string;
  gmail: string;
  one_time_pass: string;
  address: string;
  role: number;
}

interface IStaffProps {
  hideModal?: () => void;
  refetchData?: () => void;
  editAction?: boolean;
  initialStateProp?: IinitalProps | any;
}

let initialState:IinitalProps = {
  mobile: "",
  name: "",
  surname: "",
  personal_id: "",
  gmail: "",
  one_time_pass: "",
  address: "",
  role: 16,
};

export const AddStaffMembers = ({
  initialStateProp,
  hideModal,
  refetchData,
  editAction,
}:IStaffProps) => {
  const { addToast } = useToasts();
  const childRefs = useRef<{resetFields:()=>void}>();
  const [userInput, setUserInput] = useState(
    initialStateProp ? initialStateProp : initialState
  );

  const addStaff = (data:IinitalProps) => {
    return StaffController.addStaff(data);
  };

  const EditStaffMember = (data:IinitalProps) => {
    return StaffController.editStaff(data);
  };

  useEffect(() => {
    setUserInput(initialStateProp);
  }, [initialStateProp]);

  const { mutate, isLoading } = useMutation(
    editAction ? EditStaffMember : addStaff,
    {
      onSuccess: (data) => {
        addToast(`ოპერაცია წარმატებით შესრულდა!`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });

        hideModal();
        childRefs.current?.resetFields();
        refetchData();
      },
      onError: (error) => {
        addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
    }
  );

  const mockApiCall = (data) => {
    const requestBody = { ...data };
    mutate(requestBody);
  };

  return (
    <Box>
      <DynamicForm
        ref={childRefs}
        fields={staffResgistration}
        userInput={userInput}
        saveData={mockApiCall}
        loadingState={isLoading}
        buttonTitle={editAction ? "რედაქტირება" : "პერსონალის დამატება"}
      />
    </Box>
  );
};
