import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useCallback, useEffect, useState } from "react";
import OrderController from "../../../controller/orders";
import { useToasts } from "react-toast-notifications";
import InventorsAPI from "../../../config/api";

export default function CourierSearch({
  couriers,
  currentCourier,
  trxId,
  refetch,
}: {
  couriers: any;
  currentCourier: any;
  trxId: string;
  refetch: () => void;
}) {
  const initial = currentCourier
    ? {
        fullname: `${currentCourier?.firstname} ${currentCourier?.lastname}`,
        id: currentCourier?.id,
      }
    : null;
  const { addToast } = useToasts();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [defaultSelect, setdefault] = useState(initial);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const opts: [] = (await InventorsAPI.get(`/admin/couriers`)).data; // For demo purposes.

      if (active) {
        setOptions(opts);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // useEffect(() => {
  //   if (!open) {
  //     setOptions(couriers ?? []);
  //   }
  // }, [open]);

  const assignCourier = async (value) => {
    try {
      const response = await OrderController.assignCourier(
        trxId,
        value?.id ?? null
      );
      console.log(response);
      addToast(`ოპერაცია წარმატებით შესრულდა!`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } catch (err) {
      addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    }
  };

  const setNewValue = (object, value) => {
    setdefault(value);
    if (value) {
      assignCourier(value);
      refetch();
    } else {
      assignCourier(null);
    }
  };

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{
        width: 180,
        input: { fontSize: "12px" },
        label: { fontSize: "10px" },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      size="small"
      value={[defaultSelect ?? { id: -1, fullname: "აირჩიეთ კურიერი" }]}
      onChange={setNewValue}
      isOptionEqualToValue={(option, value) => option.id === value}
      getOptionLabel={(option) => option.fullname}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
