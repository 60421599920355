import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import InventorsAPI from "../../config/api/index";

export default function useDownloadExcell() {
    const [loading, setLoading] = useState<boolean>(false)
    const { addToast } = useToasts();
    const downloadExcell = async (url, name) => {
        setLoading(true)
        InventorsAPI.get(url, {
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.xlsx`);
            document.body.appendChild(link);
            link.click();
            addToast(`ოპერაცია წარმატებით შესრულდა!`, {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
            setLoading(false)
        }).catch((err) => {
            addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 5000,
            });
            setLoading(false)
        });
    }

    return { downloadExcell, loading}
}