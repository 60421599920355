/** @format */

import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { QueryClientProvider, QueryClient, QueryCache } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { theme } from "./theme/index";
import App from "./App";
import PublicationErrorBoundary from "./global/errors/public-error-boundary";


const queryClinet = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <ToastProvider placement="bottom-right">
    <React.StrictMode>
      <QueryClientProvider client={queryClinet}>
        <Provider store={store}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
            <PublicationErrorBoundary>
                <App />
            </PublicationErrorBoundary>
            </ThemeProvider>
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </React.StrictMode>
  </ToastProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
