/** @format */

import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "./components/common/header/header";
import "../src/global/css/app.css";
import Login from "./views/auth/login";
import { AuthProvider } from "./global/context/AuthContext";
import useGetToken from "./global/hooks/useGetToken";
import Main from "./views/dashboard/main";
import ProtectedRoute from "./global/security/protected-route";
import { DashboardSidebar } from "./components/dashboard/dashboard-sidebar/dashboard-sidebar";
import useLogout from "./views/auth/hooks/useLogout";
import Sellers from "./views/customers/sellers/sellers";
import SellerRating from "./views/customers/seller-rating/seller-rating";
import Couriers from "./views/customers/couriers/couriers";
import Buyers from "./views/customers/buyers/buyers";
import Staff from "./views/customers/staff/staff";
import SlideInModal from "./components/common/slide-in-modal/slide-in-modal";
import ResetPassword from "./components/auth/reset-password/reset-password";
import Offers from "./views/customers/offers";
import Orders from "./views/customers/orders/orders";
import Promo from "./views/customers/promo";

const App = () => {
  const [sidebar, setSidebar] = useState(true);
  const [checkTokenStatus, setCheckTokenStatus] = useState("");
  const { userInfo } = useGetToken(checkTokenStatus);
  const { logOut } = useLogout(setCheckTokenStatus);
  return (
    <AuthProvider value={userInfo}>
      <SlideInModal
        title="პაროლის ცვლილება"
        status={false}
        maxWidth="lg"
        setClose={() => alert("გთხოვთ შეცვალოთ პაროლი!")}
      >
        <ResetPassword />
      </SlideInModal>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          {userInfo?.userToken && sidebar ? (
            <Grid item xs={2} width="100%">
              <DashboardSidebar onLogout={logOut} setBarStatus={setSidebar} />
            </Grid>
          ) : null}

          <Grid item xs={userInfo?.userToken && sidebar ? 10 : 12}>
            <Header barSatus={sidebar} setBarStatus={setSidebar} />
            <Box>
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute
                      disableHardWareBack={true}
                      token={userInfo?.userToken}
                      redirectPath="/dashboard"
                    >
                      <Login setCheckTokenStatus={setCheckTokenStatus} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <Main />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customers/sellers"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <Sellers />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customers/rate"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <SellerRating />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customers/couriers"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <Couriers />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customers/buyer"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <Buyers />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customers/staff"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <Staff />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customers/offers"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <Offers />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customers/orders"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <Orders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customers/promo"
                  element={
                    <ProtectedRoute
                      token={userInfo?.userToken}
                      redirectPath="/"
                    >
                      <Promo />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AuthProvider>
  );
};

export default App;
