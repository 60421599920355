import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
const GridRow = ({keyName, children}:{keyName:string, children:React.ReactNode}) => {
  return (
    <Grid container spacing={2} my={2}>
      <Grid item xs={6} fontSize={13}>
        <Box component={"p"} mt={1}>
          {keyName}
        </Box>
      </Grid>
      <Grid item xs={6} fontSize={14}>
        {children}
      </Grid>
    </Grid>
  );
};

export default GridRow;
