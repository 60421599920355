import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ViaroModal from "./viaro-modal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ImageZoom from "react-image-zooom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";



// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import InventorsAPI, { baseURL } from "../../../config/api";


interface Iprops {
  imageModal:boolean,  
  setImageModal:any, 
  dbImages:string[], 
  uploadedImages:string[], 
  partName:string, 
  coordinates:{
    number:string, 
    coordinates:number[],
    groupId:string
  }
}

export default function PartImageLisder({imageModal,  setImageModal, dbImages, uploadedImages, partName, coordinates}:Iprops) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [uploaded, setUploaded] = useState([])
  const [dbPictures, setDbPictures] = useState([])
  const [imageCoord, setimageCoord] = useState<{number:string, locationX:number, locationY:number}>({number:'', locationX:0, locationY:0})
  const imageHeight = 522
  const imageWidth = 522

  useEffect(()=>{
   const uploadedImage = uploadedImages.map((el)=>{
      return `${baseURL}/storage/${el}`
    })
    setUploaded([...uploadedImage])
    setDbPictures([...dbImages])
  },[])

  //calculate coordinate posiiton on image
  useEffect(()=>{

    const getOriginalDimensions = async ()=>{
        const response = await InventorsAPI.get(`/catalog/groups/${coordinates.groupId}/img/dimensions`)
        const {width, height} = response.data
        const resizeXindex = imageWidth / width
        const resizeYindex = imageHeight / height
        const orirginalCoordinate = {
            locationX:coordinates.coordinates[0] * resizeXindex,
            locationY:coordinates.coordinates[1] * resizeYindex
        }
        setimageCoord({number:coordinates.number, ...orirginalCoordinate})
    }

    getOriginalDimensions()
  },[])


const images = [
      ...uploaded,
      ...dbPictures
  ];
  return (
    <ViaroModal
      status={imageModal}
      title="მყიდველის დეტალები"
      maxWidth="lg"
      setClose={() => setImageModal(false)}
    >
<>
     <Box my={0.5} display="flex" justifyContent="center">{partName}</Box>
      <Swiper
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >

        {images.map((el, i)=>(
            <SwiperSlide key={i}>
                 <Box sx={{position:'relative'}}>
                    <img src={el}  style={{height:imageHeight, width:imageWidth, objectFit:'fill'}}/>
                    {el.split('.')[1] == "parts-catalogs" ?                     
                    <Box sx={{position:'absolute', left:imageCoord.locationX, top:imageCoord.locationY, backgroundColor:'viaro.green', width:30, height:30, borderRadius:15,}}>
                       <Box component={"p"} sx={{position:'relative', top:8, fontSize:12}}>{imageCoord.number}</Box>
                    </Box> : null}
                 </Box>
            </SwiperSlide>
        ))}

      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={1}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
         <Box>
             {uploaded.map((el, i)=>(
                    <SwiperSlide key={i}>
                        <img src={el} style={sliderStyle.sliderMainImage} />
                    </SwiperSlide>
             ))}
         </Box>
         <Box>
             {dbPictures.map((el, i)=>(
                    <SwiperSlide key={i}>
                        <img src={el} style={sliderStyle.sliderMainImage} />
                    </SwiperSlide>
             ))}
         </Box>
      </Swiper>
    </>
    </ViaroModal>
  );
}


const sliderStyle = {
    sliderMainImage:{
        height:100,
        width:100,
        borderRadius:10,
    }
}