export const tableColumns = [
  { id: "name", label: "სახელი" },
  { id: "surname", label: "გვარი" },
  { id: "mobile", label: "ტელეფონის ნომერი" },
  { id: 'verification_status', label: 'სტატუსი' },
  { id: "personal_id", label: "პირადი ნომერი" },
  { id: "iban", label: "ანგარიშის ნომერი" },
  { id: "city", label: "ქალაქი" },
  { id: "auto_type", label: "მანქანის ტიპი" },
  { id: "car_type", label: "მანქანის მოდელი" },
  { id: 'registration_date', label: 'რეგისტრაციის თარიღი' },
  { id: 'edit', label: 'რედაქტირება', minWidth:100},
  { id: 'delete', label: 'წაშლა', minWidth:100}
];



export const courierRegistration = [
  {
    key: "name",
    label: "სახელი",
    value: "",
    node: "input",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    key: "surname",
    label: "გვარი",
    value: "",
    node: "input",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    node: "input",
    key: "mobile",
    label: "ტელეფონის ნომერი",
    value: "",
    inputType: "number",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
      pattern: {
        value: /^(0|[1-9]\d*)(\.\d+)?$/,
        message: "შეიყვანეთ მხოლოდ რიცხვები!",
      },
      minLength: {
        value: 9,
        message: "მობილურის უნდა შეიცავდეს 9 სიმბოლოს!",
      },
      maxLength: {
        value: 9,
        message: "მობილურის უნდა შეიცავდეს 9 სიმბოლოს!",
      },
    },
  },
  {
    key: "personal_id",
    label: "პირადი ნომერი",
    value: "",
    node: "input",
    inputType: "number",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
      minLength: {
        value: 11,
        message: "პირადი ნომერი უნდა შეიცავდეს 11 სიმბოლოს!",
      },
      maxLength: {
        value: 11,
        message: "პირადი ნომერი  უნდა შეიცავდეს 11 სიმბოლოს!",
      },
    },
  },
  {
    key: "car_type",
    label: "მანქანის ტიპი",
    value: "",
    node: "input",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    key: "city",
    label: "ქალაქი",
    value: "",
    node: "input",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    key: "auto_type",
    label: "ავტომობილის ტიპი",
    value:[
      {id:1, label:"სკუტერი"},
      {id:2, label:"მსუბუქი ავტომობილი"},
      {id:3, label:"მიკროავტობუსი"},
      {id:4, label:"ფურგონი"}
    ],
    node: "select",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    key: "iban",
    label: "ანგარიშის ნომერი",
    value: "",
    node: "input",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
      minLength: {
        value: 22,
        message: "ანგარიშის ნომერი უნდა შეიცავდეს 22 სიმბოლოს!",
      },
      maxLength: {
        value: 22,
        message: "ანგარიშის ნომერი უნდა შეიცავდეს 22 სიმბოლოს!",
      },
    },
  },
];
