/**
 * /* eslint-disable
 *
 * @format
 */

import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import AuthController from "../../../controller/auth";
import { useToasts } from "react-toast-notifications";
import unixTime from "../../../global/helper/unixTime";

interface IloginRes {
  token: string;
  tokenExpiresAt: Date;
  user: {
    firstname: string;
    admin: any;
    role:number
    lastname:string
  };
}

const Login = (data:{password:string, mobile:string}) => {
  return AuthController.Login(data.mobile, data.password);
};

export default function useUserLogin(
  setCheckTokenStatus: React.Dispatch<React.SetStateAction<string>>
) {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  return useMutation(Login, {
    onSuccess: (data: IloginRes) => {
      let {
        token,
        tokenExpiresAt,
        user: { firstname, role, lastname},
      } = data;
      setCheckTokenStatus(token);
      localStorage.setItem("idToken", JSON.stringify(token));
      localStorage.setItem("expires", JSON.stringify(unixTime(tokenExpiresAt)));
      localStorage.setItem("name", encodeURIComponent(firstname));
      localStorage.setItem("adminRole", JSON.stringify(role));
      localStorage.setItem("userSurname", JSON.stringify(lastname));
      navigate("/dashboard", {
        replace: true,
      });
      addToast(`გამარჯობა ${firstname}!`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    },
    onError: (error: any) => {
      if (error?.request?.statusText) {
        addToast(`მომხმარებლის პაროლი ან მობილურის ნომერი არასწორია!`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      } else {
        addToast(`სისტემაში შესვლისას მოხდა შეცდომა!`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    },
  });
}
