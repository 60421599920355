import { Visibility } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "moment/locale/ka";
import React from "react";
import { StatusSelector } from "./status-selector";

type Props = {
  data: any;
  onActionClick: (id: number) => void;
};

export function OfferTable({ data, onActionClick }: Props) {
  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell align="center">გამყიდველის სახელი</TableCell>
          <TableCell align="center">მობილური</TableCell>
          <TableCell align="center">სტატუსი</TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {data?.map((row) => (
          <TableRow key={row.id}>
            <TableCell
              scope="row"
              style={{ minWidth: 100, maxWidth: 100, width: 100 }}
            >
              {row.id}
            </TableCell>

            <TableCell
              align="center"
              style={{ minWidth: 200, maxWidth: 200, width: 200 }}
            >
              {row.seller?.firstname} {row.seller?.lastname}
            </TableCell>

            <TableCell
              align="center"
              style={{ minWidth: 200, maxWidth: 200, width: 200 }}
            >
              {row.seller?.mobile}
            </TableCell>

            <TableCell
              align="center"
              style={{ minWidth: 250, maxWidth: 250, width: 250 }}
            >
              <StatusSelector
                data={{
                  statusId: row.status,
                  id: row.id,
                  timeRemainig: row.request?.time,
                }}
              />
            </TableCell>

            <TableCell
              align="center"
              style={{ minWidth: 200, maxWidth: 200, width: 200 }}
            >
              <IconButton
                color="primary"
                component="label"
                onClick={() => onActionClick(row.id)}
              >
                <Visibility />
              </IconButton>
            </TableCell>

            <TableCell align="right" />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
