import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import CourierController from "../../../../controller/couriers";



const getCouriers = (page = 1, searchParams) => {
    return CourierController.getCourier(page, searchParams)
}

export default function useGetCouriers(page, searchParams) {
    const { addToast } = useToasts();
    return useQuery(["couriers", page], () => getCouriers(page, searchParams), {
        select: (data) => {
            const couriers = data.data
            const last_page = data.last_page
            const couriersData = couriers?.map((courier) => {
                return {
                    name: courier.firstname,
                    surname: courier.lastname,
                    mobile: courier.mobile,
                    personal_id: courier?.courier_info?.personal_id,
                    iban: courier?.courier_info?.iban,
                    city: courier?.courier_info?.city,
                    car_type: courier?.courier_info?.car_type,
                    registration_date: courier.created_at,
                    id: courier?.courier_info?.user_id,
                    verification_status: courier?.verification_status,
                    auto_type: courier?.courier_info.auto_type
                }
            })

            return {
                last_page,
                couriersData
            }
        },
        onError: (error) => {
            addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 5000,
            });
        },
    });
}