import React, { Component, Fragment, PropsWithChildren } from "react";

export default class PublicationErrorBoundary extends Component<
  PropsWithChildren<{}>
> {
  state = { error: false, errorMessage: "", errorInfo: "" };

  static getDerivedStateFromError(error, errorInfo) {
    // Update state to render the fallback UI

    return { error: true, errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an error reporting service like Sentry
    console.log({ error, errorInfo });

    this.setState((state, props) => {
      return { errorInfo: errorInfo };
    });
  }

  render() {
    const { error, errorMessage, errorInfo } = this.state;

    const { children } = this.props;

    return error ? (
      <ErrorFallbackUI {...{ error, errorMessage, errorInfo }} />
    ) : (
      children
    );
  }
}

function ErrorFallbackUI({ errorMessage, errorInfo }) {
  return (
    <div className="article-error">
      <h3>There was a problem displaying the article:</h3>
      <h3 className="error">{errorMessage}</h3>
      <h3>{JSON.stringify(errorInfo)}</h3>
    </div>
  );
}
