/** @format */

// prettier-ignore
/* eslint-disable */

import { Box } from '@mui/material';
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import React, { useCallback } from "react";
import { useAppSelector } from '../../../hooks';
import { deepOrange} from '@mui/material/colors';

const ActiveUserInfo = ({ action }:{action:()=>void}) => {
  const user = useAppSelector((store) => store.user.user);
  
  const stringAvatar = ()=>{
     let nameFirstChar = user.name.split('')[0]
     let surnameFirstChar = user.userSurname.split('')[0]
     return `${nameFirstChar}${surnameFirstChar}`
  }
  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar sx={{ bgcolor: '#10B981', p:3}}>{stringAvatar()}</Avatar>
        </Stack>
        <Box>
          <IconButton aria-label="სისტემიდან გასვლა" onClick={action}>
            <Logout style={{ color: '#10B981' }} />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default ActiveUserInfo;


