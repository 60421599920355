export enum ChosenEntityCondition {
    originalNew = "originalNew",
    originalUsed = "originalUsed",
    replicaNew = "replicaNew",
}


enum Warranty {
    noWarranty = -1
}


export function entityStatusTitle(value: ChosenEntityCondition) {
    const title = {
        [ChosenEntityCondition.originalNew]: "ახალი / ორიგინალი",
        [ChosenEntityCondition.originalUsed]: "მეორადი / ორიგინალი",
        [ChosenEntityCondition.replicaNew]: "ახალი / არაორიგინალი",
    };
    return title[value];
}



export function warrantyStatusTitle(warranty:number){
    if(warranty == Warranty.noWarranty){
        return `გარანტიის გარეშე`
    }else{
        return `${warranty} დღე`
    }
}