import { Box, Collapse } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import "moment/locale/ka";
import React, { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useDataApi } from "../../../../../config/api";
import OffersController from "../../../../../controller/offers";
import {
  IColumn,
  IOfferRow
} from "../../../../../views/customers/offers/interface";
import { BuyerRow } from "./buyer-row";
import { OfferTable } from "./offer-table";
import { RescheduleRow } from "./reschedule-row";

type Props = {
  row: IOfferRow;
  columns: IColumn[];
  onActionClick?: (data: number) => void;
};

export function OfferTableRow({ row, columns, onActionClick }: Props) {
  const { addToast } = useToasts();
  const [{ data, isLoading, isError }, setData, setUrl] = useDataApi(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setUrl("admin/offers/" + row.id);
    }
  }, [open]);

  const handleReschedule = useCallback(() => {
    OffersController.reschedule(row.status, row.request.id)
      .then(() => {
        addToast("ოპერაცია წარმატებით შესრულდა", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      })
      .catch(() => {
        addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
  }, [row]);

  return (
    <>
      <BuyerRow
        columns={columns}
        row={row}
        isExpanded={open}
        toggleExpand={() => setOpen((open) => !open)}
      />
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {(() => {
              if (isLoading) return null;
              if (row.all_offer_parts_count < 1) {
                return <RescheduleRow row={row} handleReschedule={handleReschedule} />;
              }

              return (
                <Box>
                  <OfferTable data={data} onActionClick={onActionClick} />
                </Box>
              );
            })()}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
