/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({
  token,
  children,
  redirectPath = "/landing",
  disableHardWareBack,
}) => {
  if (!token && !disableHardWareBack) {
    return <Navigate to={redirectPath} replace />;
  } else if (token && disableHardWareBack) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  redirectPath: PropTypes.string,
  disableHardWareBack: PropTypes.bool,
  children: PropTypes.node,
  token: PropTypes.string,
};

export default ProtectedRoute;
