import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import StaffController from "../../../../controller/staff";




const getStaffMembers = (page = 1, searchParams) => {
    return StaffController.getStaff(page, searchParams)
}

export default function useGetStaffMembers(page, searchParams) {
    const { addToast } = useToasts();
    return useQuery(["staff", page], () => getStaffMembers(page, searchParams), {
        select: (data) => {
            const staff = data.data
            const last_page = data.last_page
            const staffData = staff?.map((member) => {
                return {
                    name: member.firstname,
                    surname: member.lastname,
                    mobile: member.mobile,
                    personal_id: member?.stuff_info.personal_id,
                    gmail: member?.stuff_info.gmail,
                    verification_status: member?.verification_status,
                    address: member?.stuff_info.address,
                    one_time_pass: member?.stuff_info.one_time_pass,
                    registration_date: member?.stuff_info.created_at,
                    id: member.id,
                    role: member.role,
                }
            })

            return {
                last_page,
                staffData
            }
        },
        onError: (error) => {
            addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 5000,
            });
        },
    });
}