/** @format */

/* eslint-disable */

import React from "react";
import { Box, Container, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import LoginForm from "../../components/auth/login-form/login-form";

const Login = ({ setCheckTokenStatus }:{setCheckTokenStatus:React.Dispatch<React.SetStateAction<string>>}) => {
  const image = require("../../images/csd-overlay.jpg");
  return (
    <Container maxWidth={false} disableGutters className="overlay">
      <Box
        display="flex"
        flexDirection={"row"}
        justifyContent={"center"}
        sx={{
          backgroundImage: `url(${image})`,
          height: "100vh",
          backgroundSize: "cover",
        }}
      >
        <FormControl sx={{ width: "100%", maxWidth: "500px" }}>
          <Paper
            elevation={1}
            sx={{
              width: "100%",
              marginY: "90px",
              paddingX: {
                lg: "50px",
                xs: "20px",
              },
            }}
          >
            <Box mt={5}>
              <Box component={"h2"} textAlign={"center"}>
                ავტორიზაცია
              </Box>
            </Box>
            <Box sx={{ paddingY: { lg: "50px", xs: "20px" } }}>
              <LoginForm setCheckTokenStatus={setCheckTokenStatus} />
            </Box>
          </Paper>
        </FormControl>
      </Box>
    </Container>
  );
};

export default Login;
